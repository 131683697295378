/*loyalty-offer*/

.loyalty-offer .offer-type {
    text-align: center;
    padding: 0 15px 20px 15px;
}

.loyalty-offer .offer-type.enthusiast {
    background: #FFFBF9;
}

.loyalty-offer .offer-type.corkscrew {
    background: #FEF5F1;
}

.loyalty-offer .offer-type.lover {
    background: #FBEFEA;
}

.loyalty-offer .offer-type.connoisseur {
    background: #F8EBE5;
}

.loyalty-offer .offer-type.expert {
    background: #F3E6E0;
}

.loyalty-offer .offer-type.vip {
    background: #F3E0D8;
}

.loyalty-offer .border-block {
    border-bottom: 1px solid #D9D9D9;
    padding: 22px 0 15px 0;
}

.loyalty-offer .offer-type .offer-type-title {
    color: #000;
    font-family: FSerProBold,sans-serif;
    font-size: 18px;
}

.loyalty-offer .offer-type .border-block img {
    height: 58px;
    margin-bottom: 8px;
}

.loyalty-offer .offer-type .price-info {
    color: #000000;
    font-family: "FedraSansProBook", sans-serif;
    font-size: 18px;
}
.loyalty-offer .offer-type .price-info.percent {
    font-family: "FedraSansPro", sans-serif;
    font-weight: 500;
    font-size: 20px;
}

.loyalty-offer .offer-type .available-type {
    padding: 15px 0;
    height: 88px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loyalty-offer .offer-type .available-type img {
    height: 30px;
}

.loyalty-offer .offer-info-block {
    padding-bottom: 20px;
    padding-right: 15px;
}

.loyalty-offer .level-and-price {
    color: #7B7B7B;
    text-transform: uppercase;
    font-size: 14px;
    font-family: "FedraSansProBook", sans-serif;
    line-height: 28px;
}

.loyalty-offer .more-price {
    color: #000;
    font-family: "FedraSansProBook", sans-serif;
    font-size: 15px;
    padding: 15px 0;
    height: 88px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.loyalty-offer .more-price span {
    display: block;
    color: #757474;
    font-size: 12px;
}

.loyalty-offer .offer-info-block .level-block {
    height: 131px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.loyalty-offer .owl-theme .owl-dots .owl-dot span {
    background: #EAE8E7;
    width: 12px;
    height: 12px;
}

.loyalty-offer .owl-theme .owl-dots .owl-dot.active span {
    background: #fff;
    border: 1px solid #461754;
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
    .loyalty-offer .offer-type .offer-type-title,
    .loyalty-offer .offer-type .price-info {
        font-size: 16px;
    }

    .loyalty-offer .offer-type .price-info.percent {
        font-size: 18px;
    }
    .loyalty-offer .offer-type .border-block img {
        height: 52px;
    }

    .loyalty-offer .offer-info-block .level-block {
        height: 122px;
    }

    .loyalty-offer .offer-info-block .level-and-price {
        line-height: 25.5px;
    }
    .loyalty-offer .offer-info-block .more-price,
    .loyalty-offer .offer-type .available-type {
        height: 96px;
    }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
    .loyalty-offer .offer-type .offer-type-title {
        font-size: 13px;
    }

    .loyalty-offer .offer-type .border-block img {
        height: 46px;
    }
    .loyalty-offer .offer-type .price-info {
        font-size: 14px;
        max-width: 70px;
        margin: 0 auto;
    }

    .loyalty-offer .offer-type .price-info.percent {
        font-size: 18px;
    }

    .loyalty-offer .offer-type .available-type {
        height: 25px;
    }

    .loyalty-offer .offer-info-block .level-block {
        height: 111px;
    }

    .loyalty-offer .offer-info-block .level-and-price {
        line-height: 34.5px;
        font-size: 13px;
    }

    .loyalty-offer .offer-info-block .more-price {
        height: 116px;
        font-size: 14px;
    }
    .loyalty-offer .offer-type .available-type {
        height: 116px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .loyalty-offer .offer-type .level-and-price {
        line-height: 21px;
    }

    .loyalty-offer .offer-type {
        padding: 15px 10px 15px 10px;
        margin-bottom: 5px;
    }

    .loyalty-offer .offer-type .price-info.percent,
    .loyalty-offer .offer-type .price-info {
        font-size: 16px !important;
    }

    .loyalty-offer .border-block {
        padding: 18px 0;
    }

    .loyalty-offer .offer-type .available-type {
        display: block;
        text-align: left;
        height: auto;
        padding: 4px 14px 4px 15px;
    }

    .loyalty-offer .offer-type .available-type.unavailable {
        opacity: 0.4;
    }

    .loyalty-offer .offer-type .price-info {
        padding: 0 5px;
        color: #E81E25;
        font-size: 20px !important;
        margin-bottom: 12px;
    }

    .loyalty-offer .offer-type .price-info.percent {
        margin: 0 auto 28px auto;
        background: #E81E25;
        border-radius: 100%;
        width: 90px;
        height: 90px;
        padding: 0 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        font-size: 20px !important;
        position: relative;
    }
    .loyalty-offer .offer-type .price-info.percent:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 116px;
        height: 1px;
        background: #D9D9D9;
        margin-bottom: -16px;
        margin-left: -58px;
    }

    .loyalty-offer .offer-type .price-info.percent .level-and-price {
        color: #FFFFFF;
        font-size: 12px;
        line-height: 12px;
        margin-top: 12px;
    }

    .loyalty-offer .available-type .more-price {
        font-size: 14px;
        height: auto;
        margin-bottom: 10px;
        padding: 0;
        font-weight: 600;
    }

    .loyalty-offer .available-type.unavailable .more-price {
        font-weight: 400;
    }

    .loyalty-offer .offer-type .img-type {
        height: 58px;
        margin-bottom: 6px;
    }

    .loyalty-offer .offer-type .offer-type-title {
        position: relative;
        display: table;
        margin: 0 auto 24px auto;
    }
    .loyalty-offer .offer-type .offer-type-title:after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 116px;
        height: 1px;
        background: #D9D9D9;
        margin-left: -58px;
        margin-bottom: -12px;
    }

    .loyalty-offer .offer-type .available-type.unavailable img {
        margin-top: -5px;
        width: 14px;
        min-width: 14px;
        margin-left: 0;
    }

    .loyalty-offer .offer-type .available-type img {
        margin-left: -4px;
        min-width: 18px;
        width: 18px;
        margin-top: -2px;
    }


}