/* base styles

================================================== */


* {
    margin: 0;
    padding: 0;
    outline: 0;
}

body,
html {
    height: 100%;
    scroll-behavior: smooth;
}

body {
    font-family: 'FedraSansPro', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #6A6665;
}

::selection {
    background: #461754;
    color: #fff;
}

::-moz-selection {
    background: #461754;
    color: #fff;
}


:focus {
    outline: 0
}

img {
    max-width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    border: none;
}

.products-list img {
    min-width: 100%;
}

.products-list .empty-category img {
    min-width: 200px;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

.space {
    padding: 100px 0;
}

.space-bot {
    padding-bottom: 100px;
}

/* typography
================================================== */
h1, h2, h3, h4, h5, h6 {
    font-family: 'FSerProBold', sans-serif;
    color: #000;
}

h1 {
    font-size: 26px;
    line-height: 32px;
}

h2 {
    font-size: 22px;
    line-height: 28px;
}

h3 {
    font-size: 18px;
    line-height: 28px;
}

h4 {
    font-size: 18px;
    line-height: 28px;
}

h5 {
    font-size: 18px;
    line-height: 28px;
}

h6 {
    font-size: 18px;
    line-height: 28px;
}

strong {
    font-weight: 700;
}

a {
    color: #6A6665;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

a:hover,
a:active,
a:focus {
    color: #e30613;
    outline: 0;
    text-decoration: none;
}

p {
    color: #6A6665;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.5;
}

.heading {
    margin: 0 0 60px 0;
    font-size: 28px;
    line-height: 34px;
    color: #000;
    font-family: 'FSerProB', sans-serif;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
}

.btn {
    height: 44px;
    line-height: 42px;
    padding: 0 30px;
    border-radius: 0;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.btn-primary {
    background-color: #e30613;
    border-color: #e30613;
    color: #fff;
}

.btn-primary:hover {
    background-color: #fff;
    border-color: #e30613;
    color: #e30613;
}

.btn-success:hover,
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
    background-color: #fff;
    border-color: #28a745;
    color: #28a745;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    border-color: #e30613;
    color: #e30613;
    box-shadow: none;
}

#sumup-card form {
    display: none;
}

.btn-dark {
    background: #303539;
    border-color: #303539;
    color: #ffffff;
}

.btn-dark:hover {
    background: #181E23;
    border-color: #181E23;
    color: #ffffff;
}

.btn-outline-dark {
    background: #ffffff;
    border-color: #303539;
    color: #303539;
}

.btn-outline-dark:hover {
    background: #303539;
    color: #ffffff;
}

.btn-secondary {
    background-color: #461754;
    border-color: #461754;
    color: #fff;
}

.btn-secondary-outline {
    background-color: transparent;
    border-color: #461754;
    color: #461754;
}

.btn-secondary-outline:hover {
    border-color: #461754;
    background-color: #461754;
    color: #fff;
}

.button-outline-primary {
    color: #059EA5;
    border-color: #059EA5;
    background: #fff;
}

.button-outline-primary:hover,
.button-outline-primary:focus {
    background: #059EA5;
    color: #fff;
}

label {
    margin-bottom: 3px;
}

textarea.form-control {
    padding: 10px 15px;
}

.form-control {
    height: 44px;
    padding: 0 15px;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #e3e2e2;
    font-size: 14px;
}

.form-control::placeholder {
    color: #b5b2b2;
}

.form-control:focus {
    box-shadow: none;
    border-color: #e30613;
}

.form-icon {
    position: relative;
}

.form-icon img {
    position: absolute;
    right: 15px;
    max-height: 20px;
    max-width: 18px;
}

.container {
    max-width: 1300px;
}

/*top*/
.top {
    background-color: #461754;
    padding: 10px 0;
}

.top .top-item {
    display: inline-block;
    position: relative;
    margin-right: 30px;
}

.top .top-item:last-of-type:after {
    display: none;
}

.top .top-item:after {
    content: "";
    height: 15px;
    width: 1px;
    background-color: #753e86;
    display: block;
    position: absolute;
    top: 3px;
    right: -16px;
}

/*langs*/
.langs ul .lang-item {
    display: inline-block;
    position: relative;
}

.langs .lang-icon {
    margin-right: 10px;
}

.langs ul .lang-item .lang-item-link .nav-arrow {
    margin-left: 2px;
}

.langs ul .lang-item .lang-item-link {
    color: #fff;
    display: block;
    position: relative;
}

.langs ul > .lang-item:hover > .sub-lang {
    display: block;
}

.langs ul .sub-lang:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #e30613;
}

.langs ul.sub-lang {
    background-color: #fff;
    position: absolute;
    left: 0;
    right: auto;
    margin-top: 10px;
    padding: 8px 0;
    display: block;
    display: none;

    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}

.langs ul.sub-lang:after {
    content: "";
    height: 16px;
    width: 100%;
    position: absolute;
    top: -16px;
    left: 0;
}

.langs ul.sub-lang li:hover > ul.sub-lang {
    display: block;
}

.langs ul.sub-lang li > ul.sub-lang {
    top: 0;
    left: 0;
    display: none;
}

.langs ul .sub-lang .sub-lang-item {
    display: block;
    position: relative;
}

.langs ul .sub-lang .sub-lang-item-link {
    display: block;
    position: relative;
    padding: 5px 20px;
}

.langs ul .sub-lang .sub-lang-item-link:hover {
    color: #e30613;
}

/*social*/
.social ul li {
    display: inline-block;
    margin: 0 5px;
}

.social ul li a {
    color: #fff;
}

.social ul li a:hover {
    opacity: .8;
}

/*vouchers*/
.vouchers a {
    color: #fff;
    opacity: .7;
}

.vouchers a:hover {
    text-decoration: underline;
    opacity: 1;
}

/*phone*/
.phone {
    color: #fff;
}

.phone span {
    opacity: .7;
}

.phone a {
    color: #fff;
    margin-left: 10px;
}

.phone a:hover {
    text-decoration: underline;
}

/*delivery hint*/
.delivery-hint {
    background-color: #e30613;
    padding: 8px 0;
    text-align: center;
    color: white;
}

.delivery-hint span {
    margin: 0 10px;
}

/*user widgets*/
.user-widgets {
    text-align: right;
}

.user-widgets.user-widgets-left {
    text-align: left;
}

.user-widgets.user-widgets-left .widget-item:first-of-type {
    padding-left: 0;
}

.user-widgets .widget-item {
    display: inline-block;
    padding: 0 6px;
    position: relative;
}

.user-widgets .widget-item .fav-num {
    position: absolute;
    left: 25px;
    top: -2px;
    background-color: #E30613;
    color: #e2e2e2;
    width: 13px;
    height: 13px;
    line-height: 12px;
    font-size: 10px;
    text-align: center;
    border-radius: 100%;
}

.user-widgets .widget-item .cart-num {
    position: absolute;
    left: 16px;
    top: -2px;
    background-color: #E30613;
    color: #e2e2e2;
    width: 13px;
    height: 13px;
    line-height: 12px;
    font-size: 10px;
    text-align: center;
    border-radius: 100%;
}

.user-widgets .widget-item .cart-sum {
    display: inline-block;
    font-size: 12px;
    margin-left: 5px;
    position: relative;
    top: 5px;
}

.user-widgets .widget-item .icn {
    height: 22px;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.user-widgets .widget-item:hover .icn {
    opacity: .4;
}

/*user nav*/
.user-widget .user-nav {
    position: absolute;
    top: 43px;
    right: 0;
    z-index: 3;
    background-color: #fff;
    padding: 10px 0 10px;
    text-align: left;
    display: none;
    border-top: 2px solid #d9d9d9;

    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}

.user-widget .user-nav:before {
    content: "";
    width: 100%;
    height: 25px;
    position: absolute;
    left: 0;
    top: -26px;
}

.user-widget .user-nav:after {
    content: "";
    height: 2px;
    width: 33px;
    position: absolute;
    top: -2px;
    right: 0;
    background-color: #e30613;
    z-index: 3;
}

.user-widget .user-nav ul li {
    white-space: nowrap;
}

.user-widget .user-nav ul li a {
    display: block;
    position: relative;
    padding: 5px 20px;
}

.user-widget:hover .user-nav {
    display: block;
}

.category-text {
    scroll-margin-top: 200px;
    scroll-snap-margin: 200px;
    margin-top: 30px;
}

.category-text h2,
.category-text h3,
.category-text h4,
.category-text h5,
.category-text h6 {
    margin: 0 0 10px 0;
}

.error-page {
    text-align: center;
    padding: 0 50px;
}

.error-page .error-code {
    font-size: 130px;
    line-height: 90px;
    font-weight: bold;
    margin: 0 0 18px 0;
    color: #333;
    font-family: Arial;
}

.error-page .error-text {
    margin: 0 0 20px 0;
    font-size: 14px;
    line-height: 18px;
}

/*header*/
header {
    padding-top: 25px;
    background-color: #fff;
    border-bottom: 1px solid #d7d7d7;

    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}

#header-placeholder {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 105;
}


header.fixed {
    width: 100%;
    padding-top: 5px;
    border-color: transparent;
    box-shadow: 0 7px 10px 0 rgba(162, 162, 162, 0.12),
    0 0 1px 0 rgba(144, 144, 144, 0.25);
}

header.fixed .logo img {
    height: 30px;
}

header.fixed .mega-menu {
    top: 58px;
}

header .logo {
    margin: 0 auto;
    display: table;
    transition: none;
}

header .logo img {
    height: 44px;

    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}

/*search*/
.search {
    position: relative;
    max-width: 300px;
}

.search.open {
    display: block;
}

.search input[type=text] {
    border: 0;
    padding: 10px 0;
    width: 100%;
    padding-right: 38px;
    border-bottom: 2px solid #d9d9d9;
}

.search button {
    border: 0;
    background-color: transparent;
    position: absolute;
    right: 3px;
    top: 10px;
    color: #d9d9d9;
    outline: none;
}

.search button img {
    height: 22px;
}

.search .search-results {
    overflow: auto;
    position: relative;
    padding: 10px 0;
}

.search .search-container {
    position: absolute;
    top: 43px;
    padding: 0 15px;
    z-index: 4;
    background-color: #fff;
    width: 100%;

    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}

.search .search-results .found {
    font-size: 12px;
}

.search .search-results .result-row {
    padding: 15px 15px;
    background-color: #fff;
    border-top: 0;
    text-align: left;
    border-bottom: 1px solid #d9d9d9;
}

.search .search-results .result-row:last-of-type {
    border-bottom: 0;
}

.search .search-results .result-row .product-title {
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    color: #000;
    display: inline-block;
    font-family: 'FSerProB', sans-serif;
}

.search .search-results .result-row .product-title:hover {
    color: #e30613;
}

.search .all-results {
    text-align: center;
    display: block;
    font-size: 12px;
    padding: 10px 0;
    border-top: 1px solid #d9d9d9;
    color: #ababab;
}

.search .all-results:hover {
    color: #e30613;
}

.search .no-results {
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    color: #ababab;
}

/*cart*/
.cart-container {
    position: absolute;
    top: 43px;
    right: 0;
    width: 315px;
    z-index: 11;
    background-color: #fff;
    display: table;
    padding: 10px 15px 20px;
    text-align: left;
    display: none;
    border-top: 2px solid #d9d9d9;

    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}

.cart-container:before {
    content: "";
    width: 100%;
    height: 25px;
    position: absolute;
    left: 0;
    top: -26px;
}

.cart-widget:after {
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -22px;
    background-color: #e30613;
    display: none;
    z-index: 11;
}

.cart-widget:hover:after {
    display: block;
}

.cart-widget:hover .cart-container {
    display: block;
}

.cart-container .cart-result {
    border-bottom: 1px solid #ddd;

    max-height: 340px;
    padding-right: 20px;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
}

.ps > .ps__rail-x,
.ps > .ps__rail-y {
    opacity: 0.6;
}

.cart-container .cart-result .result-row {
    padding: 15px 0;
    border-bottom: 1px solid #d9d9d9;
}

.cart-container .cart-result .result-row:last-of-type {
    border: 0;
}

.cart-container .cart-result .product-title {
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    display: block;
    margin: 0 0 10px 0;
}

.cart-container .cart-result .product-remove {
    position: relative;
    top: -4px;
    font-size: 24px;
    line-height: 1;
    cursor: pointer;
    font-weight: normal;
    color: #dcdcdc;
}

.cart-container .cart-result .product-mill {
    color: #cecece;
}

.cart-container .cart-total {
    font-size: 16px;
    padding: 10px 0;
    color: #cecece;
}

.cart-container .cart-result .product-amount {
    color: #cecece;
}

.cart-container .cart-result .product-amount span {
    font-size: 14px;
    line-height: 18px;
    color: #6A6665;
}

.cart-container .cart-result .product-amount small {
    font-size: 14px;
    line-height: 18px;
    color: #6A6665;
}

.cart-container .empty-cart {
    padding: 20px;
    text-align: center;
    color: #cecece;
}

.cart-container .empty-cart img {
    margin: 0 auto 15px;
    display: block;
    height: 60px;
}

.cart-container .cart-total span {
    float: right;
    color: #6A6665;
}

.cart-container .btn {
    width: 100%;
    display: block;
    margin: 5px 0 0 0;
}

.cart-empty {
    text-align: center;
}

.cart-empty img {
    height: 150px;
    margin: 0 0 10px 0;
}

/*menu*/
nav {
    margin: 20px auto 0;
    display: table;
}

nav .menu .menu-item {
    display: inline-block;
    margin: 0 20px;
    margin-bottom: -1px;
}

nav .menu .menu-item.active .menu-link:before {
    display: block;
    background-color: #4e1e50;
}

nav .menu .menu-item:hover .menu-link:before {
    display: block;
}

nav .menu .menu-item .menu-link:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    top: 57px;
    left: 0;
    background-color: #e30613;
    display: none;
    z-index: 160;
}

nav .menu .menu-item.rel {
    position: relative;
}

nav .menu .menu-item .menu-link {
    font-size: 16px;
    text-transform: uppercase;
    color: #333;
    position: relative;
    padding: 17px 0;
    display: inline-block;
}

nav .menu .menu-item .dropdown-btn {
    margin-left: 10px;
    display: inline-block;
}

nav .menu .menu-item.active .menu-link {
    color: #4e1e50;
}

nav .menu .menu-item:hover .menu-link,
nav .menu .menu-item.offer-special .menu-link {
    color: #e30613;
}

nav .menu .menu-item .menu-link:hover:after,
nav .menu .menu-item.offer-special .menu-link:after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);

    -moz-opacity: 100;
    -khtml-opacity: 100;
    opacity: 100;
    opacity: 1;
}

nav .menu .sub-menu {
    background-color: #fff;
    left: -20px;
    right: auto;
    position: absolute;
    top: 58px;
    padding: 8px 0;
    border-top: 1px solid #d7d7d7;
    z-index: 102;
    display: none;

    -webkit-box-shadow: 15px 20px 15px rgba(0, 0, 0, .10);
    -moz-box-shadow: 15px 20px 15px rgba(0, 0, 0, .10);
    box-shadow: 15px 20px 15px rgba(0, 0, 0, .10);
}

nav .menu .sub-menu li:hover > ul.sub-menu {
    display: block;
}

nav .menu .sub-menu li > ul.sub-menu:before {
    display: none;
}

nav .menu .sub-menu li > ul.sub-menu {
    top: 0;
    left: 100%;
    right: auto;
    display: none;
}

nav .menu .sub-menu .sub-menu-item {
    display: block;
    position: relative;
}

nav .menu .sub-menu-arrow {
    position: absolute;
    right: 16px;
    top: 10px;
}

nav .menu .sub-menu .sub-menu-link {
    display: block;
    position: relative;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    width: 230px;
    margin: 0;
    padding: 10px 20px;
}

.mega-menu {
    position: absolute;
    display: none;
    top: 78px;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 155;
    padding: 20px 20px 20px;
    border-top: 1px solid #d7d7d7;
    box-shadow: 0 4px 3px 0 rgba(162, 162, 162, 0.12),
    0 0 1px 0 rgba(144, 144, 144, 0.25);
}

.mega-menu h5 {
    margin: 0 0 15px 0;
    color: #ababab;
    font-family: 'FedraSansPro', sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
}

.mega-menu .mega-menu-container {
    margin: 0 auto;
    display: table;
    width: 100%;
    max-width: 1300px;
}

.mega-menu .mega-menu-list li {
    margin: 4px 0;
}

.mega-menu .mega-menu-list li a {
    font-size: 13px;
}

.mega-menu .mega-menu-list > li {
    position: relative;
    padding: 0 0 0 15px;
}

.mega-menu .mega-menu-list.red-dot > li:before {
    content: "";
    background-color: #e30613;
    position: absolute;
    top: 6px;
    left: 0;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 100%;
}

.mega-menu .mega-menu-list.red-dot > li > a {
    color: #461754;
    font-family: 'FSerProB', sans-serif;
    font-weight: bold;
}

.mega-menu .mega-menu-list > li > a:hover {
    color: #e30613;
}

.mega-menu .mega-menu-list > li > ul {
    margin: 0 0 20px 0;
}

.mega-menu-list {
    margin: 0 0 20px 0;
}

/*intro*/
.intro h2 {
    color: #4e1e50;
    font-size: 44px;
    line-height: 60px;
    font-weight: bold;
    margin: 0 0 30px 0;
}

.intro .btn {
    text-transform: uppercase;
    letter-spacing: 3px;
    padding: 0 45px;
}

.intro .carousel-caption {
    bottom: 15%;
}

.intro .carousel-indicators {
    bottom: 30px;
    z-index: 5;
}

.intro .carousel-item {
    background-size: contain;
}

/*benefits - not used at that point*/

/*.home .benefits {*/
/*    border-bottom: 2px solid #efe1d4;*/
/*    padding: 60px 0 40px;*/
/*}*/

/*.home .benefits .benefit {*/
/*    position: relative;*/
/*}*/

/*.home .benefits .benefit p {*/
/*    text-align: center;*/
/*    font-size: 10px;*/
/*    color: #fff;*/
/*    position: absolute;*/
/*    top: 42%;*/
/*    width: 100%;*/
/*    padding: 0 10px;*/
/*    height: 38px;*/
/*    line-height: 38px;*/
/*    background-color: #461754;*/
/*    border-color: #461754;*/
/*    box-shadow: 8px 10px 10px #ddd;*/
/*    opacity: 0;*/
/*}*/

/*.home .benefits .benefit:hover p {*/
/*    opacity: 1;*/
/*}*/

/*.home .benefits .benefit .benefit-img {*/
/*    height: 100px;*/
/*    margin: 0 0 15px 0;*/
/*}*/

/*.home .benefits .benefit .benefit-img img {*/
/*    height: 100px;*/
/*    margin: 0 auto;*/
/*    display: block;*/
/*}*/

/*.home .benefits .benefit h5 {*/
/*    color: #461754;*/
/*    text-transform: uppercase;*/
/*    font-size: 16px;*/
/*    line-height: 22px;*/
/*    text-align: center;*/
/*    padding: 0 20px;*/
/*    height: 44px;*/
/*    font-family: 'FedraSansPro', sans-serif;*/
/*}*/

/*.home .benefits .benefit .btn {*/
/*    padding: 0 10px;*/
/*    font-size: 13px;*/
/*    text-transform: initial;*/
/*    display: block;*/
/*    letter-spacing: 0;*/
/*    opacity: 0;*/
/*    position: absolute;*/
/*    top: 42%;*/
/*    width: 100%;*/
/*    box-shadow: 8px 10px 10px #ddd;*/

/*    -webkit-transition: all .4s;*/
/*    -moz-transition: all .4s;*/
/*    -o-transition: all .4s;*/
/*    transition: all .4s;*/
/*}*/

/*.home .benefits .benefit:hover .btn {*/
/*    opacity: 1;*/
/*}*/


.gift-package {
    padding: 20px;
    border: 1px solid #e3e2e2;
    background-color: #fff;
}

.gift-card,
.gift {
    padding: 15px 25px;
    border: 1px solid #e3e2e2;
    margin: 0 0 30px 0;
}

.gift-card .switch,
.gift .switch {
    float: right;
}

.gift-card h3,
.gift h3 {
    color: #461754;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    padding: 0 0 5px 0;
}

.gift-card p,
.gift p {
    margin: 0;
}

.gift-card-action h3 .switch,
.gift-action h3 .switch {
    float: right;
}

.gift-action h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    color: #461754;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.gift-action .choose-period {
    margin: 20px 0 30px 0;
}

.gift-action .choose-period h4 {
    margin: 0 0 5px 0;
}

.gift-action .choose-period p {
    margin: 0 0 20px 0;
}

.gift-card-action .card-text {
    margin: 20px 0 0 0;
}

.gift-card-action .card-text .card-lab {
    padding: 15px 20px;
    border-top: 1px solid #e3e2e2;
    border-left: 1px solid #e3e2e2;
    border-right: 1px solid #e3e2e2;
}

.gift-card-action .card-text .card-lab img {
    margin-right: 15px;
}

.gift-card-action .card-text .card-lab h5 {
    font-size: 16px;
    line-height: 22px;
    font-family: 'FedraSansPro', sans-serif;
}

.gift-card-action .card-text .card-lab p {
    font-size: 14px;
    margin: 0;
}

.gift-card-action .card-text .form-control {
    width: 100%;
}

.gift-card-action h3 {
    color: #461754;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    padding: 0 0 15px 0;
}

.cart {
    padding: 45px 0 100px;
}

.cart h1 {
    margin: 0 0 8px 0;
    font-size: 28px;
    line-height: 34px;
    color: #4e1e50;
    font-family: 'FSerProB', sans-serif;
    font-weight: bold;
}

.back-to-shop {
    font-size: 12px;
}

.back-to-shop + .cart-headings {
    margin-top: 20px;
}

.cart-benefits {
    padding: 30px 0 0;
}

.guarante-cart {
    text-align: center;
    margin: 20px 0 0 0;
}

.guarante-cart img {
    margin-right: 5px;
}

.cart-list {
    background-color: #FBFAF9;
    padding: 10px 25px;
}

.cart-list .product-name {
    font-family: 'FSerProB', sans-serif;
    margin: 0 0 5px 0;
    display: inline-block;
    font-weight: bold;
    color: #000;
    font-size: 14px;
    line-height: 20px;
}

.cart-list .product-name:hover {
    color: #e30613;
}

.cart-list .product-old-price {
    color: #ddd;
}

.remove-product:focus {
    outline: none;
}

.remove-product img {
    width: 16px;
}

.remove-product {
    outline: none;
    border: 0;
    background-color: transparent;
    margin-left: 10px;
    opacity: .5;
    position: absolute;
    top: 0;
    right: 15px;

    width: 16px;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.remove-product:hover {
    opacity: 1;
}

.cart-list .product-desc {
    font-size: 12px;
    line-height: 18px;
    color: #afafaf;
}

.cart-list .product-desc div {
    margin-bottom: 2px;
    margin-top: 10px;
}

.cart-delivery-hint {
    background-color: #EDDDCD;
    padding: 11px 15px;
    margin: 25px 0 20px 0;
}

.cart-headings {
    padding: 10px 25px;
}

.cart-list .cart-row {
    padding: 25px 0;
    border-bottom: 1px solid #E3E2E2;
}

.cart-list .cart-row .package-discounts .badge {
    font-size: 13px;
    margin: 5px 0;
}

.cart-list .cart-row:last-of-type {
    border: 0;
}

.promo-code {
    padding: 25px;
    border: 1px solid #e3e2e2;
    margin: 0 0 30px 0;
}

.promo-code .btn {
    display: block;
}

.checkout {
    padding: 0 0 100px 0;
}

.checkout .payment-method {
    margin: 0 0 15px 0;
}

.checkout .order-info .checkbox {
    margin-top: -10px;
}

.total {
    padding: 25px 0 0;
    border: 1px solid #e3e2e2;
    border-bottom: 0;
}

.total.with-border {
    border-bottom: 1px solid #e3e2e2;
}

.total h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 25px 25px 25px;
    color: #461754;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
}

.total .product-row {
    margin: 0 25px;
    border-bottom: 1px solid #e3e2e2;
    padding: 20px 0;
}

.total .product-row:first-of-type {
    padding-top: 0;
}

.total .product-row:last-of-type {
    border: 0;
}

.total .product-row .product-img {
    margin-right: 15px;
}

.total .product-row .product-name {
    font-family: 'FSerProB', sans-serif;
    font-weight: bold;
    margin: 0 0 10px 0;
    color: #000;
}

.total .product-row .product-desc {
    color: #6A6665;
    font-size: 12px;
    line-height: 16px;
}

.total .product-row .product-old-price {
    text-decoration: line-through;
    color: #ddd;
}

.total .product-row .product-price {
    color: #414141;
    text-align: right;
}

.total .product-row .product-price .event-old-price {
    text-decoration: line-through;
    color: #aaaaaa;
}

.cart .sum {
    padding-top: 0;
}

.total-action .sum {
    margin: 0 0 0;
    padding: 0 0 25px 0;
}

.total-action .checkbox label a {
    text-decoration: underline;
}

.sum {
    padding: 10px 0 25px 0;
    margin: 0 25px 0;
}

.sum ul li {
    margin: 7px 0;
}

.sum ul li span {
    float: right;
}

.sum ul li:last-of-type {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    margin-top: 12px;
    margin-bottom: 0;
}

.total .total-hint {
    padding: 20px 25px 20px 60px;
    position: relative;
    border-top: 1px solid #e3e2e2;
    background-color: #FBFAF9;
    margin: 0 0 0 0;
}

.total .total-hint p {
    color: #6A6665;
}

.total .total-hint p:last-of-type {
    margin: 0;
}

.total .total-hint img {
    position: absolute;
    top: 25px;
    left: 20px;
}

.total-action {
    padding: 25px;
    border: 1px solid #e3e2e2;
    background-color: #fff;
}

.total-action .checkbox {
    margin: 0 0 20px 0;
}

.total-action .btn {
    display: block;
    width: 100%;
}

.sticky-top {
    top: 140px;
    z-index: 3;
}

.order-payment {
    background-color: #FBFAF9;
    padding: 25px;
}

.order-payment h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    color: #461754;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.order-payment .payment-method {
    position: relative;
}

.order-payment .payment-method input[type=radio] {
    position: absolute;
    top: 50%;
    left: 18px;
    margin-top: -7.4px;
}

.order-payment .payment-method input[type=radio]:checked ~ label {
    background-color: #fff;
    border-color: #461754;
}

.order-payment .payment-method label {
    border: 1px solid #E3E2E2;
    padding: 20px 60px 20px 45px;
    width: 100%;
    cursor: pointer;
}

.order-payment .payment-method.payment-card label {
    padding: 20px 20px 20px 35px;
}

.order-payment .payment-method.payment-card label img {
    height: 19px;
}

.order-payment .payment-method label .payment-icon {
    position: absolute;
    top: 28px;
    right: 20px;
}

.order-payment .payment-method label .payment-icon img {
    max-height: 40px;
    display: block;
}

.order-payment .payment-method label .payment-name {
    font-family: 'FSerProB', sans-serif;
    color: #000;
    font-size: 13px;
    line-height: 16px;
    margin: 0 0 3px 0;
}

.order-payment .payment-method label .payment-desc {
    color: #6A6665;
    font-size: 12px;
    line-height: 16px;
}


.order-delivery {
    background-color: #FBFAF9;
    padding: 25px;
    margin: 0 0 30px 0;
}

.order-delivery h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    color: #461754;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.order-delivery .delivery-method {
    position: relative;
    margin: 0 0 20px 0;
}

.order-delivery .delivery-method input[type=radio] {
    position: absolute;
    top: 50%;
    left: 18px;
    margin-top: -6.5px;
}

.order-delivery .delivery-method input[type=radio]:checked ~ label {
    background-color: #fff;
    border-color: #461754;
}

.order-delivery .delivery-method label {
    border: 1px solid #E3E2E2;
    padding: 20px 20px 20px 45px;
    width: 100%;
    cursor: pointer;
}

.order-delivery .delivery-method label .delivery-name {
    font-family: 'FSerProB', sans-serif;
    color: #000;
    font-size: 13px;
    line-height: 16px;
    margin: 0 0 3px 0;
}

.order-delivery .delivery-method label .delivery-desc {
    color: #6A6665;
    font-size: 12px;
    line-height: 16px;
}

.order-delivery h4 {
    margin: 0 0 15px 0;
    color: #461754;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
}

.order-delivery .delivery-types {
    margin: 15px 0 0 0;
}

.order-delivery .delivery-type {
    position: relative;
    margin: 0 0 20px 0;
}

.order-delivery .delivery-type input[type=radio] {
    position: absolute;
    top: 50%;
    left: 18px;
    margin-top: -6.5px;
}

.order-delivery .delivery-type input[type=radio]:checked ~ label {
    background-color: #fff;
    border-color: #461754;
}

.order-delivery .delivery-type label {
    border: 1px solid #E3E2E2;
    padding: 20px 10px 20px 45px;
    width: 100%;
    margin: 0;
    cursor: pointer;
}

.order-delivery .delivery-type label .delivery-type-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -15px;
}

.order-delivery .delivery-type label .delivery-type-icon img {
    max-height: 25px;
    display: block;
}

.order-delivery .delivery-type label .delivery-type-name {
    font-family: 'FSerProB', sans-serif;
    color: #000;
    font-size: 13px;
    line-height: 16px;
}

.order-delivery .delivery-type label .delivery-type-desc {
    color: #6A6665;
    font-size: 12px;
    line-height: 16px;
}

.order-info {
    background-color: #FBFAF9;
    padding: 25px;
    margin: 0 0 30px 0;
}

.date-info {
    background-color: #FBFAF9;
    padding: 25px;
    margin: 0 0 30px 0;
}

.order-info .select2-container {
    width: 100% !important;
}

.order-info.payment-info {
    padding: 0;
}

.order-info h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    color: #461754;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.order-info label {
    margin: 0 0 4px 0;
}

.order-info .form-msg {
    color: #e30613;
    font-size: 12px;
    margin: 5px 0 0 0;
}

.products {
    padding-bottom: 100px;
}

.products .hero-area {
    padding: 50px 0;
}

.products .hero-area h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 0 0 15px 0;
    color: #fff;
}

.products .hero-area p {
    color: #fff;
}

.products .hero-area a {
    color: #fff;
    text-decoration: underline;
}

.sort h3 {
    font-family: 'FedraSansPro', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px 0;
    position: relative;
}

.sort.active h3:after {
    content: "\f106";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sort h3:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sort.active .sort-result {
    display: block;
}

.sort .sort-result {
    margin: 0 0 30px 0;
    display: none;
}

.sort .results-count {
    line-height: 44px;
}

.countries h3 {
    font-family: 'FedraSansPro', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 8px 0 20px 0;
    position: relative;
}

.countries.active h3:after {
    content: "\f106";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.countries h3:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.countries.active .countries-result {
    display: block;
}

.countries .countries-result {
    display: none;
}

.countries {
    margin: 0 0 30px 0;
}

.countries .view-all {
    text-align: center;
    padding: 11px 0;
}

.countries .view-all a {
    color: #afafaf;
    cursor: pointer;
    text-decoration: underline;
}

.countries .view-all a:hover {
    color: #e30613;
}

.countries .clear-filter {
    padding: 11px 0;
    text-align: left;
}

.countries .clear-filter button img {
    height: 20px;
    position: relative;
    top: -1px;
    margin-right: 1px;
}

.countries .clear-filter button {
    color: #afafaf;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border: 0;
    transition: all .4s;
}

.countries .clear-filter button:hover {
    color: #e30613;
}

.countries h3 {
    font-family: 'FedraSansPro', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px 0;
}

.countries .country {
    padding: 10px;
    border: 1px solid #e3e2e2;
    margin: 0 0 20px 0;
    text-align: center;
    color: #461754;
    cursor: pointer;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.countries .country.active {
    background-color: #461754;
    color: #fff;
    border-color: #461754;
}

.countries .country:hover {
    border-color: #461754;
}

/* product */
.product .gallery {
    position: sticky;
    top: 140px;
    margin: 0 0 50px 0;
}

.product .product-add-fav {
    background-color: transparent;
    border: 0;
    padding: 10px 0;
    outline: none;
    transition: all .4s;
}

.product .product-add-fav:hover {
    opacity: .4;
}

.product .assortment {
    padding-bottom: 100px;
}

.product .product-info {
    padding: 40px 0;
}

.product h1 {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
}

.product .product-src {
    margin: 15px 0;
}

.product .product-stock.in-stock {
    color: #179767;
    margin: 0 0 15px 0;
}

.product .product-stock.not-in-stock {
    color: #e30613;
    margin: 0 0 15px 0;
}

.product .product-stock.in-stock i, .product .product-stock.not-in-stock i {
    margin-right: 5px;
}

.product .product-rate {
    position: relative;
    display: table;
}

.product .product-rate .rate-tooltip {
    position: absolute;
    top: 40px;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #343a40;
    z-index: 3;
    display: none;
    width: 320px;

    box-shadow: 0 7px 10px 0 rgba(162, 162, 162, 0.12),
    0 0 1px 0 rgba(144, 144, 144, 0.25);
}

.product .product-rate .rate-tooltip:after {
    content: "";
    position: absolute;
    top: -23px;
    left: 0;
    height: 23px;
    width: 100%;
}

.product .product-rate .rate-tooltip:before {
    content: "";
    transform: rotate(45deg);
    width: 20px;
    height: 20px;
    position: absolute;
    top: -11px;
    left: 93px;
    background-color: #fff;
    border-left: 1px solid #343a40;
    border-top: 1px solid #343a40;
}

.product .product-rate:hover .rate-tooltip {
    display: block;
}

.product .product-rate .rate-tooltip .btn {
    margin: 15px auto 0;
    display: table;
    text-decoration: none;
}

.product .product-rate .rate-tooltip .rate-list ul {
    padding: 0 30px;
}

.product .product-rate .rate-list ul li {
    margin-bottom: 10px;
}

.product .product-rate .rate-list ul li i {
    color: #461754;
    font-size: 16px;
}

.product .product-rate .rate-list ul li .val {
    font-family: 'FedraSansPro', sans-serif;
    display: inline-block;
}

.product .product-rate .rate-list ul li .rate-progress-bar {
    display: inline-block;
    background-color: #3c4046;
    height: 5px;
    position: absolute;
}

.product .product-rate .rate-list ul li .rate-progress {
    position: relative;
    background-color: #CAC7C6;
    width: 100%;
    height: 5px;
}

.product .product-rate > ul {
    display: inline-block;
}

.product .product-rate > ul li {
    display: inline-block;
}

.product .product-rate > ul li i {
    color: #4E1E50;
}

.product .product-rate .rate {
    display: inline-block;
    margin: 0 0 0 10px;
}

.product .product-rate a {
    display: inline-block;
    font-size: 12px;
    text-decoration: underline;
}

.product .product-desc {
    margin: 20px 0 35px;
}

.variant-error {
    margin: 10px 0 0;
    font-size: 12px;
    color: #e30613;
}

.variant-error i {
    margin-right: 3px;
    font-size: 14px;
    position: relative;
    top: -1px;
}

.product .product-variants {
    background-color: #FBFAF9;
    padding: 15px;
    margin: 20px 0;
}

.product .product-variants.alerts-border {
    border: 1px #ff0000 solid;
    animation: blink 1s;
    animation-iteration-count: 1;
}

@keyframes blink {
    50% {
        border-color: #FBFAF9;
    }
}

.product .product-variants .lab {
    margin: 0;
}

.product .product-variants .product-variant {
    display: inline-block;
    margin: 10px 30px 5px 0;
    padding: 5px 20px;
    background-color: #fff;
    color: #4E1E50;
    border: 2px solid #e3e2e2;
    cursor: pointer;
    position: relative;
    opacity: 0.7;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.product .product-variants .product-variant.selected {
    border-color: #4e1e50;
    color: #4e1e50;
    opacity: 1;
}

.product .product-variants .product-variant.unavailable {
    opacity: 0.6;
}

.product .product-variants .product-variant:hover {
    border-color: #4E1E50;
    background-color: #FBFAF9;
}

.product .product-variants .product-variant img {
    height: 22px;
    margin-right: 10px;
}

.product .product-variants .product-variant .variant-discount {
    position: absolute;
    top: -14px;
    right: -18px;
    background-color: #E81E25;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    color: #fff;
    border-radius: 100%;
    font-size: 10px;
}

.product .product-prices {
    margin: 0 0 15px 0;
}

.product .product-prices .new-price {
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    color: #000;
    display: inline-block;
    margin-right: 10px;
}

.product .product-prices .old-price {
    display: inline-block;
    text-decoration: line-through;
    color: #bbb;
}

.product .product-delivery-hint {
    margin: 20px 0;
}

.product .product-delivery-hint h5 {
    font-size: 14px;
    line-height: 20px;
}

.product-quantity {
    position: relative;
    border: 1px solid #e3e2e2;
}

.product .product-features {
    padding: 70px 0;
}

.product .product-features.gray {
    background-color: #FBFAF9;
}

.product .product-features h4 {
    font-size: 18px;
    line-height: 24px;
    color: #4E1E50;
    margin: 0 0 15px 0;
}

.product .temperature img {
    margin: 0 0 8px 0;
}

.product .temperature span {
    display: block;
}

.product .region-info span {
    display: block;
    color: #c1c1c1;
}

.product .region-info span a {
    display: block;
    color: #c1c1c1;
}

.product .region-info .flag {
    display: block;
    margin: 5px 0 5px 0;
}

.product .product-sort {
    padding: 0 0 6px 0;
    margin-bottom: 50px;
    border-bottom: 1px solid #e3e2e2;
}

.product .product-aromas ul li {
    display: inline-block;
    text-align: center;
    line-height: 16px;
}

.product .product-aromas ul li img {
    display: block;
    margin: 0 auto 10px;
}

.product .suitable-foods ul li {
    display: inline-block;
    text-align: center;
    line-height: 18px;
}

.product .suitable-foods ul li img {
    display: block;
    margin: 0 auto 10px;
}

.product .product-profile ul li {
    padding: 12px 0 12px 0;
    border-bottom: 1px solid #e3e2e2;
}

.product .product-profile ul li .ingredient {
    display: inline-block;
    width: 95px;
}

.product .product-profile ul li .dot.empty {
    background-color: #DBD8DB;
}

.product .product-profile ul li .dot {
    background-color: #E81E25;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    margin: 0 4px;
    display: inline-block;
}

.review-modal button.close {
    color: #fff;
    opacity: 1;
}

.review-modal button.close:focus {
    border: 0;
    outline: none;
}

.review-modal .review-info select.form-control {
    display: inline-block;
    width: auto;
    padding: 0 8px;
    margin-left: 10px;
}

.add-review {
    overflow: scroll;
    -webkit-overflow-scrolling: touch
}

.add-review .modal-body {
    padding: 0;
}

.add-review .review-info {
    background-color: #FBFAF9;
    border-left: 1px solid #DCDBDA;
    padding: 30px 40px;
}

.add-review .product-rate {
    padding: 30px 0;
    font-size: 20px;
    font-weight: bold;
    color: #222;
}

.add-review .product-rate > ul {
    display: inline-block;
    margin-left: 15px;
    position: relative;
    top: 5px;
}

.add-review .product-rate > ul li {
    display: inline-block;
    margin-right: 7px;
}

.add-review .product-rate > .product-stars {
    position: absolute;
    display: inline-block;
    margin-left: 15px;
}

.add-review .product-rate > .product-stars input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    padding: 0;
    margin: 0;
    bottom: 0;
}

.add-review .product-rate > .product-stars .fa {
    color: #4E1E50;
    font-size: 34px;
    margin-right: 7px;
    font-weight: normal;
    float: right;
    margin-bottom: 0;
    cursor: pointer;
}

.star-rating:hover:before,
.star-rating:hover ~ .star-rating:before,
.star-rating-input:checked ~ .star-rating:before {
    content: "\f005";
}

.add-review .form-hint {
    color: #b9b9b9;
    font-size: 14px;
    margin-bottom: 10px;
}

.add-review .form-group {
    margin-bottom: 30px;
}

.add-review label, .add-review legend {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #222;
}

.add-review button {
    margin: 60px auto 0;
    display: table;
}

.add-review .review-img {
    margin: 30px auto 0;
    display: block;
    padding-left: 30px;
}

.add-review .lab-no {
    float: left;
}

.add-review .lab-yes {
    float: right;
}

.add-review .eval {
    margin: 0 0 5px 0;
    display: table;
    width: 100%;
}

.add-review .eval li {
    float: left;
    width: 10%;
}

.add-review .eval li label {
    text-align: center;
    padding: 10px 0;
    background-color: #F2F2F2;
    border-top: 1px solid #C9C9C9;
    border-bottom: 1px solid #C9C9C9;
    border-left: 1px solid #C9C9C9;
    cursor: pointer;
    display: block;
    font-size: 14px;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.add-review .eval li label:hover {
    opacity: .7;
    color: #461754;
}

.add-review .eval li input {
    display: none;
}

.add-review .eval li input:checked + label {
    background-color: #461754;
    border-top-color: #461754;
    border-bottom-color: #461754;
    color: #fff;
}

.add-review .eval li input:checked + label:hover {
    opacity: 1;
}

.add-review .eval li:last-of-type label {
    border-right: 1px solid #C9C9C9;
}

.add-review .rec-fr {
    display: table;
    width: 100%;
}

.add-review .rec-fr li {
    float: left;
}

.add-review .rec-fr li label {
    text-align: center;
    padding: 10px 25px;
    background-color: #F2F2F2;
    border-top: 1px solid #C9C9C9;
    border-bottom: 1px solid #C9C9C9;
    border-left: 1px solid #C9C9C9;
    cursor: pointer;
    display: block;
    font-size: 14px;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.add-review .rec-fr li label:hover {
    opacity: .7;
    color: #461754;
}

.add-review .rec-fr li input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    padding: 0;
    margin: 0;
    bottom: 0;
}

.add-review .rec-fr li input:checked + label {
    background-color: #461754;
    border-top-color: #461754;
    border-bottom-color: #461754;
    color: #fff;
}

.add-review .rec-fr li input:checked + label:hover {
    opacity: 1;
}

.add-review .rec-fr li:last-of-type label {
    border-right: 1px solid #C9C9C9;
}

.modal-content {
    border: 0;
    border-radius: 0;
}

.modal-header {
    border-radius: 0;
}

.add-review .modal-header {
    background-color: #461754;
}

.add-review .modal-header h5 {
    color: #fff;
    text-align: center;
    display: block;
    width: 100%;
}

.comments {
    background-color: #FBFAF9;
}

.comments .rating .lab {
    margin: 16px 0 25px;
}

.comments .rating .rate {
    font-size: 40px;
    font-weight: bold;
    color: #461754;
}

.comments .rating .count {
    font-size: 12px;
    color: #bbb;
}

.comments .rate-list {
    margin: 0 0 50px 0;
}

.comments .rate-list .btn {
    margin: 0 0 29px auto;
    display: table;
}

.comments .rate-list ul li {
    margin-bottom: 10px;
}

.comments .rate-list ul li i {
    color: #461754;
    font-size: 16px;
}

.comments .rate-list ul li .val {
    font-family: 'FedraSansPro', sans-serif;
    display: inline-block;
}

.comments .rate-list ul li .rate-progress-bar {
    display: inline-block;
    background-color: #3c4046;
    height: 5px;
    position: absolute;
}

.comments .rate-list ul li .rate-progress {
    position: relative;
    background-color: #CAC7C6;
    width: 100%;
    height: 5px;
}

.comments .comment-row {
    padding: 35px 0;
    border-bottom: 1px solid #e3e2e2;
}

.comments .comment-row .wine-recommended {
    margin: 20px 0 0 0;
}

.comments .comment-row .wine-recommended.yes {
    color: #179767;
}

.comments .comment-row .wine-recommended.no {
    color: #B70F14;
}

.comments .comment-row .wine-recommended i {
    margin-right: 2px;
}

.comments .comment-row .reviews-count {
    color: #c5c5c5;
    font-size: 12px;
}

.comments .comment-row .comment-author {
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: bold;
    color: #414141;
}

.comments .comment-row .comment-heading {
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    margin: 0 0 10px 0;
    font-weight: bold;
    color: #414141;
}

.comments .comment-row .comment-rate {
    margin: 0 0 25px 0;
}

.comments .comment-row .comment-rate ul {
    display: inline-block;
}

.comments .comment-row .comment-rate ul li {
    display: inline-block;
}

.comments .comment-row .comment-rate ul li i {
    color: #4E1E50;
}

.comments .comment-row .comment-rate span {
    color: #c5c5c5;
    margin-left: 15px;
    font-size: 12px;
}

.comments .more {
    color: #6A6665;
    padding: 35px 0 0;
    margin: 0 auto;
    display: table;
    text-decoration: underline;
}

.comments .more:hover {
    color: #e30613;
}

.comments .empty-comments {
    text-align: center;
}

.comments .empty-comments img {
    height: 60px;
    margin: 0 0 15px 0;
}

.comments .empty-comments h3 {
    margin: 0 0 3px 0;
}

.comments .comment-btn {
    text-align: center;
    margin: 30px 0 0 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.product-quantity input[type=number] {
    width: 100%;
    text-align: center;
    border: 0;
    height: 42px;
    font-size: 18px;
    line-height: 42px;
    -webkit-appearance: none;
    -moz-appearance: textfield;
}

.product-quantity button {
    background-color: rgb(70 23 84);
    border: 0;
    font-weight: bold;
    color: #fff;
    position: absolute;
    top: 0;
    height: 42px;
    width: 35px;
    outline: none;
}

.product-quantity .minus {
    left: 0;
}

.product-quantity .plus {
    right: 0;
}

/*product list*/
.products-list .product-item {
    text-align: center;
    position: relative;
}

.products-list .product-item .product-prices {
    margin: 10px 0 15px;
}

.products-list .product-item .product-name {
    font-size: 14px;
    line-height: 22px;
    font-weight: bold;
    min-height: 66px;
    margin: 0 0 30px 0;
    position: relative;
    font-family: 'FSerProB', sans-serif;
    color: #000;
    display: flex;
    align-items: start;
    justify-content: center;
}

.products-list .product-item .product-name:after {
    content: "";
    width: 30px;
    height: 1px;
    background-color: #bbb;
    position: absolute;
    bottom: -18px;
    left: 50%;
    margin-left: -15px;
}

.products-list .product-item .product-desc {
    color: #6A6665;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    min-height: 63px;
}

.products-list .product-item .product-prices .old-price {
    color: #bdbdbd;
    text-decoration: line-through;
}

.products-list .product-item .product-prices .price,
.products-list .product-item .product-prices .old-price {
    display: inline-block;
    margin: 0 5px;
    font-size: 16px;
}

.products-list .product-item .product-prices .price {
    font-weight: bold;
    color: #000;
}

.products-list .product-item {
    overflow: hidden;
    position: relative;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.products-list .product-item .product-img {
    overflow: hidden;
    position: relative;
    margin: 0 0 20px 0;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.products-list .product-item .product-info {
    position: relative;
    background-color: #fff;
    padding: 10px 0 0 0;
    z-index: 3;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.products-list .product-item:hover .product-info {
    -moz-transform: translate(0, -80px);
    -ms-transform: translate(0, -80px);
    -o-transform: translate(0, -80px);
    -webkit-transform: translate(0, -80px);
    transform: translate(0, -80px);
}

.products-list .product-item .product-action {
    opacity: 0;
}

.products-list .product-item:hover .product-action {
    opacity: 1;
}

.products-list .product-item .product-action .btn {
    width: 100%;
    display: block;
    margin: 0 0 10px 0;
    padding: 0 10px;
    font-size: 12px;
    letter-spacing: 0;
}

.products-list .product-item .product-action .btn:last-of-type {
    margin: 0;
}

.products-list.product-recently .signature img {
    height: 20px;
}

.products-list .product-item:hover .add-fav {
    opacity: 1;
}

.upsell .upsell-product {
    background-color: #F7F5F2;
    padding: 15px 10px;
    margin: 0 0 30px 0;
}

.upsell .upsell-product .btn {
    padding: 0 20px;
}

.upsell .products-list .product-item .product-action {
    opacity: 1;
}

.add-fav {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    opacity: 0;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.add-fav img {
    height: 15px;
}

.product-labels {
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
}

.product-labels .product-label {
    width: 60px;
    height: 60px;
    line-height: 17px;
    color: #fff;
    background-color: #000;
    border-radius: 100%;
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-labels .product-label.label-new {
    background-color: #461754;
}

.product-labels .product-label.label-discount {
    background-color: #e81e25;
}

.product-labels .product-label.label-discount.discount-fixed {
    width: auto;
    max-width: 100px;
    padding: 2px 5px;
    border-radius: 4px;
    height: auto;
    line-height: inherit;
}

.product-labels .product-label.label-score {
    border: 2px solid #ddd;
    background-color: #fff;
    color: #333;
    font-size: 13px;
    line-height: 36px;
}

.product-labels li {
    margin-bottom: 5px;
}

.signature {
    margin: 10px 0 0 0;
}

.signature img {
    height: 28px;
    width: inherit !important;
}

/*product recently*/
.similar-products {
    padding: 50px 70px 50px 70px
}

/*product recently*/
.product-recently {
    padding: 100px 70px 25px 70px;
}

/*new offers*/
.new-offers {
    padding: 100px 70px 25px 70px;
}

.new-offers.btn {
    padding: 0 10px;
}

/*special offers*/
.special-offers {
    background-color: #FBFAF9;
    padding-bottom: 0;
}

.special-offers .owl-carousel .owl-nav button {
    display: none;
}

.special-offers.products-list .product-item .product-info {
    background-color: #FBFAF9;
}

/*assortment*/
.assortment .assortment-item .assortment-img img {
    width: 100%;
}

.assortment .assortment-item {
    text-align: center;
    min-height: 100%;
    background-color: #fbfaf9;
    position: relative;
}

.assortment .assortment-item .assortment-info {
    background-color: #fbfaf9;
    padding: 20px 30px 70px;
}

.assortment .assortment-item .assortment-info p {
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.assortment .assortment-item .assortment-info input {
    opacity: 0;
    position: absolute;
    pointer-events: none;
}

.assortment .assortment-item .assortment-info input:focus ~ label {
    outline: -webkit-focus-ring-color auto 5px;
}

.assortment .assortment-item .assortment-info input ~ label {
    margin-bottom: 20px;
    text-decoration: underline;
    cursor: pointer;
    display: block;
    margin-top: -20px;
}

.assortment .assortment-item .assortment-info input:checked + p {
    -webkit-line-clamp: unset;
}

.assortment .assortment-item .assortment-info input:checked ~ label {
    display: none;
}

.assortment .assortment-item h3 {
    position: relative;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    margin: 0 0 30px 0;
    color: #461754;
    min-height: 52px;
}

.assortment .assortment-item h3:after {
    content: "";
    width: 30px;
    height: 1px;
    background-color: #bbb;
    position: absolute;
    bottom: -18px;
    left: 50%;
    margin-left: -15px;
}

/*offers*/
.offers {
    padding: 40px 0 40px 0;
}

.offers .main-offer {
    margin: 0 0 30px 0;
    position: relative;
    overflow: hidden;
}

.offers .main-offer img {
    width: 100%;
}

.offers .main-offer:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 40%));
    pointer-events: none;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.offers .main-offer:hover:before {
    height: 100%;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 70%));
}

.offers .main-offer-text {
    position: absolute;
    left: 0;
    bottom: 18%;
    background-color: rgba(105, 29, 94, .8);
    height: auto;
    padding: 20px 0;
    width: 100%;
    padding-left: 15%;
    z-index: 3;

    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.offers .main-offer-text h3 {
    font-family: 'FedraSansPro', sans-serif;
    color: #fff;
    font-size: 28px;
    letter-spacing: 13px;
    text-transform: uppercase;
}

.offers .main-offer,
.offers .small-offer,
.offers .assortment-item {
    position: relative;
    margin-bottom: 30px;
}

.offers .main-offer .btn,
.offers .small-offer .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    opacity: 0; /* keep this if you want it hidden initially */
}

.offers .main-offer:hover .btn,
.offers .small-offer:hover .btn {
    opacity: 1;
}

.offers .small-offer:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 40%));
    pointer-events: none;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.offers .small-offer:hover:before {
    height: 100%;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 70%));
}

/*newsletter*/
.newsletter {
    background: url('../../images/newsletter-bgr.jpg') top center no-repeat;
    background-size: cover;
    position: relative;
}

.form-check {
    position: initial;
    display: initial;
    padding: initial;
}

.newsletter:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.newsletter .container {
    position: relative;
    z-index: 3;
}

.newsletter .heading {
    color: #fff;
    margin-bottom: 20px;
}

.newsletter .form-control {
    margin: 0 0 10px 0;
}

.newsletter label {
    color: #fff;
}

.newsletter .btn {
    height: 44px;
    line-height: 36px;
}

.newsletter .hint {
    text-align: center;
    color: #fff;
    margin: 0 0 30px 0;
    padding: 0 250px;
    opacity: .8;
    font-size: 16px;
}

/*events*/
.events {
    background-color: #FBFAF9;
    background-image: url('../../images/events-bgr.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
}

.events .event-block:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60%;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 90%));

    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.events .event-block:hover:before {
    height: 100%;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 100%));
}

.events .event-block .event-info {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 25px 30px 0;
}

.events .event-block .event-info h3 {
    position: relative;
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    margin: 0 0 10px 0;
    color: #fff;
}

.events .event-block .event-info .event-meta .date {
    display: inline-block;
    color: #fff;
}

.events .event-block .event-info .event-meta .slots.slots-end {
    color: #E81E25;
    text-transform: uppercase;
}

.events .event-block .event-info .event-meta .slots {
    display: inline-block;
    margin-left: 10px;
    color: #fff;
}

.events .event-block:hover .event-info .btn {
    max-height: 46px;
    border-width: 1px;
    margin-bottom: 30px;
    opacity: 1;
}

.events .event-block .event-info .btn {
    max-height: 0;
    overflow: hidden;
    position: relative;
    border-width: 0;
    opacity: 0;
    margin-top: 10px;

    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.events .event-block {
    position: relative;
}

.events .event-space {
    margin-bottom: 30px;
}

.events .view-all {
    margin: 50px auto 0;
    display: table;
}

/*blog*/
.home .blog-list .blog-item {
    margin: 0 0 0 0;
}

.blog-list .blog-item {
    text-align: center;
    margin: 0 0 50px 0;
}

.blog-list .blog-item-img {
    display: block;
    position: relative;
}

.blog-list .blog-item-img:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 10%));

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.blog-list .blog-item-img:hover:before {
    height: 100%;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 40%));
}

.blog-list .blog-item h3 {
    margin: 20px 15px;
    min-height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-list .blog-item h3 a {
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    color: #461754;
    display: inline-block;
}

.blog-list .blog-item h3 a:hover {
    color: #e30613;
}

.blog-list .view-all {
    margin: 50px auto 0;
    display: table;
}

.blog-list .hero-area {
    padding: 110px 0;
}

.blog-list .hero-area h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 0 0 15px 0;
    color: #fff;
    text-align: center;
}

.blog-list .blog-filter {
    margin: 0 0 50px 0;
}

.blog-list .blog-filter li {
    display: inline-block;
    margin: 0 5px;
}

.blog-list .blog-filter li a {
    padding: 10px;
    border: 1px solid #e3e2e2;
    margin: 0 0 20px 0;
    text-align: center;
    color: #461754;
    cursor: pointer;
    display: block;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.blog-list .blog-filter li.active a {
    background-color: #461754;
    color: #fff;
    border-color: #461754;
}

.blog-list .blog-filter li a:hover {
    border-color: #461754;
}

.blog-list .blog-filter li:first-of-type {
    margin-left: 0;
}

.blog-list .blog-filter li:last-of-type {
    margin-right: 0;
}

.back {
    margin: 15px 0 0 0;
    display: inline-block;
}

.back i {
    margin-right: 5px;
}

.login {
    background-image: url(../../images/login-sharps.svg);
    background-repeat: repeat-y;
    background-position: bottom left;
}

.login .space, .register .space {
    padding: 50px 0;
}

.social-buttons .btn {
    width: 100%;
    margin: 5px 0;
    position: relative;
}

.social-buttons .btn .fa {
    font-size: 24px;
    position: absolute;
    top: 10px;
    left: 10px;
}

.social-buttons .btn.facebook {
    background: #415a94;
    color: #fff;
}

.social-buttons .btn.facebook:hover {
    background: #314471;
    color: #fff;
}

.social-buttons .btn.google {
    background: #cf553d;
    color: #fff;
}

.social-buttons .btn.google:hover {
    background: #ae412b;
}

.social-buttons .btn.apple {
    background: #000;
    color: #fff;
}

.social-buttons .btn.apple:hover {
    background: #333333;
}

.order-auth {
    background-image: url(../../images/login-sharps.svg);
    background-repeat: repeat-y;
    background-position: bottom left;
    background-size: 250px;
}

.order-auth-login .forgot-password-link,
.login .forgot-password-link {
    float: right;
    text-decoration: underline;
    color: #969595;
    transition: all .4s;
}

.order-auth-login .forgot-password-link:hover,
.login .forgot-password-link:hover {
    color: #e30613;
    text-decoration: none;
}

.order-auth-login .icon-mail,
.login .icon-mail {
    top: 15px;
}

.order-auth-login .icon-password,
.login .icon-password {
    top: 11px;
}

.order-auth-login button,
.login button {
    display: block;
    width: 100%;
}

.login .new-reg {
    text-align: center;
    margin: 40px 0 0 0;
}

.login .new-reg span {
    display: block;
}

.login .new-reg a {
    display: inline-block;
    text-decoration: underline;
    color: #969595;
    margin: 2px 0 0 0;
}

.login .new-reg a:hover {
    text-decoration: none;
    color: #e30613;
}

.order-auth-login {
    padding: 60px 30px;
}

.order-auth-reg {
    padding: 60px 30px;
    background-color: #FBFAF9;
}

.order-auth-reg .or {
    text-align: center;
    margin: 11px 0;
}

.order-auth-reg p {
    text-align: center;
    margin-bottom: 11px;
}

.order-auth-reg .btn {
    display: block;
}

.order-auth .heading {
    margin: 0 0 45px 0;
    font-size: 22px;
    line-height: 26px;
}

.order-auth .heading-icon {
    margin: 0 auto 20px;
    display: block;
}

.page-info {
    padding: 15px 0;
    margin: 0 0 30px 0;
}

.page-info ol li {
    display: inline-block;
    margin: 0 10px;
    position: relative;
    color: #afafaf;
}

.page-info ol li:first-of-type {
    margin-left: 0;
}

.page-info ol li a {
    text-decoration: none;
    color: #3d3b6c;
}

.page-info ol li::after {
    content: "›";
    position: absolute;
    top: 0;
    right: -12px;
}

.page-info ol li:last-of-type:after {
    display: none;
}

.sidebar .active-filters h4 {
    font-family: 'FedraSansPro', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px 0;
    position: relative;
}

.sidebar h3 {
    font-family: 'FedraSansPro', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px 0;
    position: relative;
}

.sidebar.active h3:after {
    content: "\f106";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sidebar h3:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sidebar .active-filters {
    border-bottom: 1px solid #e3e2e2;
    padding: 20px 0;
    margin: 0 0 20px 0;
    text-transform: uppercase;
}

.sidebar .active-filters ul li {
    margin: 0 0 5px 0;
}

.sidebar .active-filters .filter-type {
    font-size: 12px;
    display: inline-block;
}

.sidebar .active-filters .filter-value {
    padding: 3px 5px;
    border: 1px solid #e3e2e2;
    text-align: center;
    color: #461754;
    transition: all .4s;
    display: inline-block;
    font-size: 12px;
    margin: 3px 0 0 5px;
}

.sidebar .active-filters .filter-value span {
    position: relative;
    top: -1px;
    cursor: pointer;
}

.sidebar .active-filters .filter-value span img {
    height: 10px;
    margin-left: 5px;
}

.sidebar .active-filters .filter-value span .icn {
    display: inline-block;
}

.sidebar .active-filters .filter-value span:hover .icn {
    display: none;
}

.sidebar .active-filters .filter-value span .icn-hover {
    display: none;
}

.sidebar .active-filters .filter-value span:hover .icn-hover {
    display: inline-block;
}

.sidebar .active-filters .filter-value:hover {
    border-color: #461754;
}

.sidebar.active .widgets {
    display: block;
}

.sidebar .widget:first-of-type {
    padding-top: 0;
}

.sidebar .widget {
    border-bottom: 1px solid #e3e2e2;
    padding: 20px 0;
}

.sidebar .widget .widget-box {
    margin: 10px 0 0 0;
    display: none;
}

.sidebar .widget.widget-active .widget-box {
    display: block;
}

.sidebar .widget h4 {
    font-size: 14px;
    line-height: 20px;
    padding-right: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #222;
    font-family: 'FedraSansPro', sans-serif;
    position: relative;
    cursor: pointer;
}

.sidebar .widget h5 {
    font-size: 14px;
    line-height: 20px;
    padding: 3px 0;
    color: #222;
    font-family: 'FedraSansPro', sans-serif;
    position: relative;
    cursor: pointer;
}

.sidebar .widget .category-icon {
    font-size: 16px;
    margin-right: 5px;
}

.sidebar .widget.widget-active h4:after {
    content: "\f106";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sidebar .widget h4:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sidebar .widget .aside-dropdown > li {
    position: relative;
    padding: 0 0 0 15px;
}

.sidebar .widget .aside-dropdown > li.dd-menu ul {
    display: none;
}

.sidebar .widget .aside-dropdown > li.dd-menu:after {
    content: "\f105";
    font-family: "FontAwesome";
    top: 0;
    left: 0;
    position: absolute;
    font-size: 16px;
}

.sidebar .widget .aside-dropdown > li.dd-simple-menu:after {
    content: "\f105";
    font-family: "FontAwesome";
    top: 0;
    left: 0;
    position: absolute;
    font-size: 16px;
}

.sidebar .widget .aside-dropdown > li.dd-menu-active ul {
    display: block;
}

.sidebar .widget .aside-dropdown > li.dd-menu-active:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    left: 0;
    position: absolute;
}

.sidebar .widget .aside-dropdown > li:last-of-type {
    border: 0;
}

.sidebar .widget .aside-dropdown > li > a {
    padding: 3px 0;
    display: block;
    font-size: 14px;
    line-height: 20px;
}

.sidebar .widget .aside-dropdown > li > a:hover {
    color: #e30613;
}

.sidebar .widget .aside-dropdown > li > ul {
    padding: 0 0 15px 0;
}

.sidebar .widget .aside-dropdown > li > ul > li > a {
    padding: 4px 15px 4px 10px;
    display: block;
    line-height: 18px;
    color: #afafaf;
}

.sidebar .widget .aside-dropdown > li > ul > li > a:hover {
    color: #e30613;
}

.sidebar .widget .sort-search {
    position: relative;
    margin: 0 0 20px 0;
}

.sidebar .widget .sort-search img {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 18px;
}

.sidebar .widget .sort-search .form-control {
    font-size: 12px;
    padding-left: 35px;
}

.sidebar .widget .filter-list {
    max-height: 200px;
    overflow-x: auto;
    position: relative;
}

.sidebar .widget .filter-list .filter-item {
    padding: 0 0 0 28px;
    position: relative;
    margin: 10px 0;
}

.sidebar .widget .filter-list .filter-item a {
    color: #afafaf;
    -webkit-appearance: none;
}

.sidebar .widget .filter-list .filter-item a:hover {
    color: #e30613;
}

.sidebar .widget .filter-list .filter-item.selected a {
    color: #e30613;
}

.sidebar .widget .filter-list .filter-item.selected:after {
    content: "";
    background: url(../../images/icons/check.svg) center center no-repeat;
    background-size: 24px;
    position: absolute;
    top: 0;
    left: 0;
    width: 19px;
    height: 20px;
}

.sidebar .widget .filter-list .filter-item:before {
    content: "";
    width: 18px;
    height: 18px;
    border: 1px solid #e3e2e2;
    display: block;
    position: absolute;
    top: 1px;
    left: 0;
}

.sidebar .widget .filter-list .filter-item.selected:before {
    border-color: #e30613;
}

aside .clear-all {
    margin: 20px 0;
}

aside .clear-all a {
    color: #afafaf;
    font-size: 12px;
    text-decoration: underline;
}

aside .clear-all a:hover {
    color: #e30613;
}

/*footer*/
footer {
    background-image: url(../../images/footer-sharps.svg);
    background-repeat: repeat-y;
    background-position: top left;
    background-color: #3C4046;
    background-size: 100px;
    padding: 85px 0 50px;
    content-visibility: auto;
    position: relative;
    z-index: 3;
}

footer h3 {
    color: #fff;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 20px 0;
}

footer ul li {
    margin-bottom: 10px;
    display: block;
}

footer ul li a {
    color: #b3b3b3;
}

footer ul li a:hover {
    text-decoration: underline;
    color: #cecece;
}

footer .footer-contacts.visit-us a {
    color: #b3b3b3;
}

footer .footer-contacts.visit-us a:hover {
    text-decoration: underline;
    color: #cecece;
}

footer .footer-contacts.visit-us span {
    font-size: 18px;
    display: block;
}

footer .footer-contacts.visit-us span img {
    position: absolute;
    left: 0;
}

footer .footer-contacts li {
    color: #b3b3b3;
    margin-bottom: 30px;
    padding-left: 28px;
    position: relative;
}

footer .footer-contacts li a {
    color: #e30613;
    display: block;
    font-size: 18px;
    line-height: 24px;
}

footer .footer-logo {
    margin: 60px auto 0;
    display: table;
}

footer .footer-logo img {
    height: 40px;
}

/*form components*/
.radio,
.checkbox {
    padding: 0 0 0 26px;
    position: relative;
}

.radio input[type="radio"],
.checkbox input[type="checkbox"] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    position: absolute;
}

.checkbox label::before {
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    top: 2px;
    border: 1px solid #cccccc;
    background-color: #fff;
}

.checkbox label::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    left: 3px;
    top: 2px;
    font-size: 12px;
    color: #555555;
}

.checkbox input[type="checkbox"]:checked + label::after {
    font-family: "FontAwesome";
    content: "\f00c";
}

.radio label::before {
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;

    border: 1px solid #cccccc;
    border-radius: 50%;
    background-color: #fff;

    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
}

.radio label::after {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    left: 3px;
    top: 3px;
    border-radius: 50%;
    background-color: #555555;

    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);

    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type="radio"] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.radio input[type="radio"]:checked + label::after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
}

/*owl*/
.owl-carousel .owl-nav {
    width: 100%;
}

.owl-carousel .owl-nav button .fa {
    font-size: 60px;
    line-height: 60px;
    height: 63px;
    display: block;
}

.owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    top: 50%;
    left: -60px;
    margin-top: -32px;
}

.owl-carousel .owl-nav button.owl-next {
    position: absolute;
    top: 50%;
    right: -60px;
    margin-top: -32px;
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: none;
    color: #869791;
}

.owl-theme .owl-nav [class*=owl-] {
    outline: none;
}

.carousel-indicators li {
    border: 3px solid #d5d1cf;
    background-color: #d5d1cf;
    border-radius: 100%;
    height: 7px;
    width: 7px;
    margin: 0 6px;
}

.carousel-indicators li.active {
    border: 2px solid #461754;
    background-color: transparent;
}

.user-widget .user-nav,
.sub-lang {
    z-index: 125;
}

.fix-phone span {
    width: 0;
    font-size: 0;
}

.fix-phone i {
    display: block;
    font-size: 32px;
    line-height: 60px;
}

.fix-phone {
    width: 60px;
    height: 60px;
    display: block;
    background-color: #461754;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 105;
}

.fix-phone:hover,
.fix-phone:active,
.fix-phone:focus {
    color: #fff;
}

.switch label {
    cursor: pointer;
}

.switch label input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch label input[type="checkbox"]:not(:checked),
.switch label input[type="checkbox"]:checked {
    position: absolute;
    pointer-events: none;
    opacity: 0;
}

.switch label .lever {
    position: relative;
    display: inline-block;
    margin: 0 1rem;
    margin-right: .625rem;
    vertical-align: middle;
    content: "";
    background-color: #E2E1E1;
    border-radius: .9375rem;
    width: 2.5rem;
    height: .9375rem;
    -webkit-transition: background .3s ease;
    transition: background .3s ease;
}

.switch label .lever:after {
    position: absolute;
    top: -.1875rem;
    left: -.3125rem;
    display: inline-block;
    content: "";
    background-color: #8C8B8B;
    border-radius: 1.3125rem;
    width: 1.3125rem;
    height: 1.3125rem;
    -webkit-transition: left .3s ease, background .3s ease, -webkit-box-shadow 1s ease;
    transition: left .3s ease, background .3s ease, -webkit-box-shadow 1s ease;
    transition: left .3s ease, background .3s ease, box-shadow 1s ease;
    transition: left .3s ease, background .3s ease, box-shadow 1s ease, -webkit-box-shadow 1s ease;
}

.switch label input[type="checkbox"]:checked + .lever,
.switch label .form-check input[type="checkbox"]:checked + .lever {
    background-color: #A479B2;
}

.switch label input[type="checkbox"]:checked + .lever:after,
.switch label .form-check input[type="checkbox"]:checked + .lever:after {
    left: 1.5rem;
    background-color: #4E1E50;
}

.cstm-pagination {
    margin: 0 auto;
    display: table;
}

.cstm-pagination ul {
    font-size: 0;
}

.cstm-pagination ul li {
    display: inline-block;
    font-size: 12px;
}

.cstm-pagination ul li .page-numbers {
    font-size: 14px;
    line-height: 36px;
    display: block;
    width: 38px;
    height: 38px;
    text-align: center;
    margin: 0 6px;
    border: 1px solid #e3e2e2;
    border-radius: 100%;
}

.cstm-pagination ul li .page-numbers:hover {
    border: 1px solid #461754;
    color: #461754;
}

.cstm-pagination ul li.active .page-numbers {
    border: 1px solid #461754;
    background-color: #461754;
    color: #fff;
}

.page-content .select2-container--bootstrap4 .select2-selection {
    border-radius: 0;
}

.ps__thumb-y {
    border-radius: 0;
}

.ps .ps__rail-y,
.ps .ps__rail-y {
    background-color: #eee;
    opacity: 0.6;
    width: 8px;
}

.ps .ps__rail-y:hover,
.ps .ps__rail-y:focus,
.ps .ps__rail-y.ps--clicking {
    background-color: #eee;
    opacity: 0.9;
    width: 15px;
}

.owl-stage {
    padding-left: 0 !important;
}

/* ============================================= */
.thankyou {
    background-image: url(../../images/thankyou-left-sharp.svg), url(../../images/thankyou-right-sharp.svg);
    background-position: left 90px, right bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: 150px, 150px;
}

.thankyou h1 {
    font-size: 34px;
    line-height: 40px;
    margin: 0 150px 30px 150px;
    text-align: center;
    color: #461754;
}

.thankyou .thankyou-cups {
    margin: 0 auto 30px;
    display: block;
}

.thankyou .total {
    margin-top: 50px;
}

.register h1,
.login h1 {
    color: #461754;
}

.register {
    background-image: url(../../images/login-sharps.svg);
    background-repeat: repeat-y;
    background-position: bottom left;
    background-size: 150px;
}

.register .icon-name {
    top: 11px;
}

.register .icon-mail {
    top: 15px;
}

.register .icon-password {
    top: 10px;
}

.reset-password .icon-password {
    top: 10px;
}

.reset-password h1 {
    color: #461754;
}

.reset-password h3 {
    font-family: 'FedraSansPro', sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.reset-password {
    background-image: url(../../images/login-sharps.svg);
    background-repeat: repeat-y;
    background-position: bottom left;
    background-size: 150px;
}

.reset-password .back {
    margin: 30px auto 0;
    display: table;
}

.reset-password button {
    display: block;
    width: 100%;
}

.register button {
    display: block;
    width: 100%;
}

.register .exist-reg {
    text-align: center;
    margin: 40px 0 0 0;
}

.register .exist-reg span {
    display: block;
}

.register .exist-reg a {
    display: inline-block;
    text-decoration: underline;
    color: #969595;
    margin: 2px 0 0 0;
}

.register .exist-reg a:hover {
    text-decoration: none;
    color: #e30613;
}

.faq {
    background-image: url(../../images/textpage-sharps.svg);
    background-repeat: no-repeat;
    background-position: right 90px;
    background-size: 150px;
}

.faq h1 {
    color: #461754;
}

.faq .faq-category {
    margin: 0 0 30px 0;
}

.faq .faq-category:last-of-type {
    margin: 0 0 0 0;
}

.faq .faq-category h3 {
    margin: 0 0 15px 0;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'FedraSansPro', sans-serif;
}

.faq .question {
    background-color: #fff;
    padding: 13px 55px 13px 0;
    cursor: pointer;
    position: relative;
    font-family: "FedraSansPro", sans-serif;
    font-weight: 600;
    color: #414141;
}

.faq .question.collapsed {
    box-shadow: none;
    background-color: #fff;
}

.faq .question.active {
    background: #fafafa;
}

.faq .accordion {
    margin: 0 0 50px 0;
}

.faq .accordion .accordion-item {
    border-bottom: 1px solid #eaeaea;
}

.faq .accordion:last-of-type {
    margin: 0;
}

.faq .accordion .question:after {
    content: "-";
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -15px;
    font-size: 20px;
}

.faq .accordion .question.collapsed:after {
    content: "+";
    margin-top: -13px;
}

.faq .answer .answer-content {
    padding: 10px 0 30px;
    opacity: .6;
}

.faq .faq-heading {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin: 0 0 10px 0;
    text-transform: uppercase;
}

.forgot-password h1 {
    color: #461754;
}

.forgot-password h3 {
    font-family: 'FedraSansPro', sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.forgot-password {
    background-image: url(../../images/login-sharps.svg);
    background-repeat: repeat-y;
    background-position: bottom left;
    background-size: 150px;
}

.forgot-password .icon-mail {
    top: 13px;
}

.forgot-password .back {
    margin: 30px auto 0;
    display: table;
}

.forgot-password button {
    display: block;
    width: 100%;
}

.card-payment h1 {
    color: #461754;
}

.card-payment .back {
    margin: 0;
}

.empty-category {
    text-align: center;
}

.empty-category .btn {
    margin: 10px auto 0;
    display: table;
}

.empty-category h3 {
    margin: 0 0 5px 0;
    color: #461754;
}

.empty-category img {
    margin: 0 auto;
    display: block;
}

.textpage {
    background-image: url(../../images/textpage-sharps.svg);
    background-repeat: no-repeat;
    background-position: right 90px;
    background-size: 100px;
}

.textpage h1 {
    color: #461754;
}

.textpage h3 {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 10px 0;
    font-family: 'FedraSansPro', sans-serif;
    font-weight: bold;
}

.contacts {
    background-color: #FBFAF9;
    position: relative;
}

.contacts .map {
    position: relative;
    z-index: 2;
    height: 400px;
    width: 100%;
}

.contacts h1 {
    color: #461754;
    margin: 0 0 20px 0;
}

.contacts h4 {
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 10px 0;
    font-weight: bold;
    font-family: 'FedraSansPro', sans-serif;
}

.contacts .contact-form .form-group {
    margin-bottom: 30px;
}

.contacts .contact-form {
    background-color: #fff;
    padding: 45px 55px 80px 55px;
    border-left: 1px solid #DCDAD9;
    z-index: 2;
    position: relative;
}

.contacts .contact-form p {
    margin: 0 0 40px 0;
}

.contacts .contact-info {
    padding: 45px 0 0 0;
}

.contacts .contact-info ul li {
    border-bottom: 1px solid #DCDAD9;
    padding: 25px 15px 25px 30px;
}

.contacts .contact-info ul li a {
    color: #e30613;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    margin: 5px 0;
    display: block;
}

.contacts .contact-info ul li p {
    margin: 0;
}

.contacts .contact-info ul li span {
    font-weight: bold;
    color: #000;
    display: block;
}

.favorites.products {
    padding-bottom: 0;
}

.favorites h1 {
    color: #461754;
}

.about-us .page-info {
    background-color: #FBFAF9;
    margin: 0;
    background-image: url(../../images/about-page-info-sharp.svg);
    background-repeat: no-repeat;
    background-position: right 0;
    background-size: 150px;
}

.about-us .about-text {
    padding: 50px 0;
    text-align: center;
}

.about-us .about-text p {
    font-size: 16px;
    line-height: 24px;
}

.about-us .hero-area {
    padding: 120px 0;
}

.about-us .hero-area h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 0 0 15px 0;
    color: #fff;
    text-align: center;
}

.team {
    background-image: url(../../images/textpage-sharps.svg);
    background-repeat: no-repeat;
    background-position: right 90px;
    background-size: 100px;
    padding-bottom: 30px;
}

.team .person {
    margin: 0 0 70px 0;
}

.team .person-name {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #461754;
    font-family: 'FSerProB', sans-serif;
    margin: 20px 0 8px 0;
}

.team .person-pos {
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin: 0 0 20px 0;
}

.team a {
    margin: 0 auto;
    display: table;
    text-transform: uppercase;
    opacity: .6;
    text-decoration: underline;
}

.team a:hover {
    color: #e30613;
}

.team .person-text {
    text-align: center;
    margin: 0 0 25px 0;
}

.team .person-text p:last-of-type {
    margin: 0;
}

.testimonials {
    background-color: #FBFAF9;
}

.testimonials .testimonial {
    text-align: center;
}

.testimonials .testimonial .testimonial-img {
    border-radius: 100%;
    margin: 25px auto 10px;
    width: 60px;
    height: 60px;
    display: block;
}

.testimonials .testimonial .testimonial-author {
    margin: 0 0 10px 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    color: #000;
}

.testimonials .btn {
    margin: 15px auto 0;
    display: table;
}

.payment-bank {
    padding: 25px;
    border: 1px solid #e3e2e2;
    margin-top: 50px;
}

.payment-bank h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    color: #461754;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
}

.payment-bank ul li {
    clear: both;
    border-top: 1px solid #e3e2e2;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.payment-bank ul li:last-of-type {
    padding-bottom: 0;
}

.payment-bank ul li:first-of-type {
    border: 0;
    padding-top: 0;
}

.payment-bank ul li .lab {
}

.payment-bank ul li .val {
    font-weight: bold;
    color: #000;
    text-align: right;
    padding-left: 20px;
}

.short-map iframe {
    height: 550px;
}

.testimonials {
    background-image: url(../../images/testimonials-sharp.svg);
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 150px;
}

.mb-cart-heading {
    display: none;
}

.range-slider {
    position: relative;
    height: 70px;
}

.irs--round .irs-from, .irs--round .irs-to,
.irs--round .irs-single {
    background-color: #461754;
    border-radius: 0;
}

.irs--round .irs-from:before, .irs--round .irs-to:before,
.irs--round .irs-single:before {
    border-top-color: #461754;
}

.irs--round .irs-min, .irs--round .irs-max {
    border-radius: 0;
    color: #fff;
    background-color: #e30613;
}

.irs--round .irs-bar {
    background-color: #e30613;
}

.irs--round .irs-handle {
    border: 4px solid #461754;
}

.extra-controls {
    padding: 0 30px;
}

.extra-controls input {
    width: 100%;
    text-align: center;

    height: 30px;
    padding: 0 15px;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #e3e2e2;
    font-size: 14px;
}

.extra-controls input:focus {
    box-shadow: none;
    border-color: #e30613;
}

/*Profile*/
.profile {
    background-color: #FBFAF9;
    position: relative;
}

.profile .heading {
    text-align: left;
    margin: 0 0 40px 0;
    color: #461754;
}

.profile:after {
    content: "";
    min-height: 100%;
    width: 30%;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
}

.profile .profile-content {
    background-color: #fff;
    padding: 65px 55px 100px 55px;
    border-left: 1px solid #DCDAD9;
    position: relative;
    height: 100%;
    z-index: 1;
}

.profile .profile-content .order-row .order-num {
    margin-bottom: 15px;
}

.profile .profile-content .order-row .order-num span {
    padding-right: 15px;
}

.profile .profile-content .order-row .order-num span.btn-text {
    max-width: 140px;
    display: block;
    padding: 0 7px 0 0;
}

.profile .profile-content .order-row .order-num a.replay,
.profile .profile-content .order-row .order-num a.cancel,
.profile .profile-content .order-row .order-num a.pay-again {
    position: relative;
    font-size: 11px;
}

.profile .profile-content .order-row .order-num a.replay:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    background: url("../../images/icon_1.svg") center no-repeat;
    background-size: 100%;
    width: 18px;
    height: 18px;
    transform: translate(50%, -50%);
    margin-left: 3px;
}

.profile .profile-content .order-row .order-num a.cancel:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    background: url("../../images/icon_2.svg") center no-repeat;
    background-size: 100%;
    width: 18px;
    height: 18px;
    transform: translate(50%, -50%);
    margin-left: 3px;
}

.profile .profile-content .order-row .order-num a.cancel:hover:before {
    background: url("../../images/icon_2-white.svg") center no-repeat;
}

.profile .profile-content .order-row .order-num a.pay-again span {
    position: relative;
}

.profile .profile-content .order-row .order-num a.pay-again span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    background: url("../../images/icon_3.svg") center no-repeat;
    background-size: 100%;
    width: 18px;
    height: 18px;
    transform: translate(-50%, -64%);
    margin-left: -15px;
}

.profile .profile-content .order-row .order-num a.pay-again:hover span:before {
    background: url("../../images/icon_3-greenn.svg") center no-repeat;
}

.profile .form-group {
    margin: 0 0 30px 0;
}

.profile-orders .order-row {
    padding: 20px;
    border: 1px solid #ECECEC;
    margin-bottom: 30px;
}

.profile-orders .order-row:last-of-type {
    margin-bottom: 0;
}

.profile-orders .order-num {
    font-family: 'FSerProB', sans-serif;
    padding: 0 0 20px 0;
    border-bottom: 1px solid #ECECEC;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    color: #461754;
}

.profile-content .order-row {
    padding: 10px 20px 20px 20px;
    border: 1px solid #ECECEC;
    margin-bottom: 30px;
}

.profile-content .order-row a.btn {
    height: auto;
    line-height: 17px;
    padding: 6px 6px 6px 38px;
    max-width: 147px;
    text-align: left;
    min-height: 48px;
    display: flex;
    align-items: center;
}

.profile-content .order-row a.btn.pay-again {
    max-width: 180px;
}

.profile-content .order-row:last-of-type {
    margin-bottom: 0;
}

.profile-content .order-num {
    font-family: 'FSerProB', sans-serif;
    padding: 0 0 20px 0;
    border-bottom: 1px solid #ECECEC;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    color: #461754;
}

.profile .order-details.silver-bgr {
    border: 0;
    background-color: #FBFAF9;
}

.profile .order-details:last-of-type {
    margin-bottom: 0;
}

.profile .order-details h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    color: #461754;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.profile .order-details .lab {
    color: #909090;
}

.profile .order-details .val {
    font-weight: bold;
    font-size: 14px;
    color: #000;
}

.profile .order-details .active {
    font-weight: bold;
    font-size: 14px;
    color: white;
    background-color: forestgreen;
    text-align: center;
    max-width: 130px;
    border-radius: 4px;
}

.profile .order-details .inactive {
    font-weight: bold;
    font-size: 14px;
    color: white;
    background-color: #dc3545;
    text-align: center;
    max-width: 130px;
    border-radius: 4px;
}

.profile .order-details {
    padding: 20px;
    border: 1px solid #ECECEC;
    margin: 0 0 30px 0;
}

.profile-order .order-products h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    color: #461754;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.profile-order .order-products {
    padding: 0 20px;
    border: 1px solid #ECECEC;
    margin: 0 0 30px 0;
}

.profile-order .order-products .product-row:last-of-type {
    border-bottom: 0;
}

.profile .order-products {
    border-top: 1px solid #ECECEC;
}

.profile .order-products .product-row {
    padding: 20px 0;
    border-bottom: 1px solid #ECECEC;
}

.profile .order-products .product-row .product-name {
    font-weight: bold;
    font-size: 14px;
    color: #000;
    margin: 0 0 5px 0;
}

.profile .order-products .product-row .product-img {
    max-width: 80px;
}

.profile .order-products .product-row .lab {
    margin: 0 0 7px 0;
    color: #909090;
}

.profile .order-row .view-order {
    display: table;
    margin: 17px 0 0 auto;
}

.profile .order-row .view-order i {
    margin-left: 5px;
}

.profile .order-products .product-row .product-desc {
    color: #909090;
}

.profile .product-price {
    text-align: right;
}

.profile-address-list .btn {
    margin: 30px 0 0 0;
    display: table;
}

.profile-address-list .edit-address {
    display: block;
}

.profile-address-list .primary-address {
    font-family: 'FedraSansPro', sans-serif;
    font-weight: bold;
    color: #fff;
    padding: 2px 15px;
    background-color: #461754;
    float: right;
    font-size: 12px;
    text-transform: uppercase;
}

.profile .add-address {
    padding: 20px;
    background-color: #FBFAF9;
}

.profile .add-address h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    color: #461754;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.profile.profile-cards .btn-sm {
    padding: .25rem .5rem;
    font-size: 12px;
    line-height: 1.5;
    height: auto;
    letter-spacing: 0;
    text-transform: none;
}

.user-menu {
    margin: 60px 0 60px 0;
}

.user-menu li {
    display: block;
    border-bottom: 1px solid #DCDAD9;
}

.user-menu li a:hover,
.user-menu li.active a {
    background-color: #fff;
    color: #461754;
}

.user-menu li a {
    display: block;
    padding: 12px 10px;
    text-transform: uppercase;
    position: relative;
    color: #909090;
}

.user-menu li a img {
    height: 18px;
    vertical-align: sub;
    margin-right: 7px;
    min-width: 25px;
}

.user-menu li a img.icn {
    display: inline-block;
}

.user-menu li a:hover img.icn {
    display: none;
}

.user-menu li a img.icn-hover {
    display: none;
}

.user-menu li a:hover img.icn-hover {
    display: inline-block;
}

.user-menu li a:hover {
    color: #461754;
}

.user-menu li a:after {
    content: "\f105";
    font-family: FontAwesome;
    font-size: 22px;
    position: absolute;
    top: 5px;
    right: 10px;
    color: #b5b5b5;
}

.checkout-modal .close {
    display: table;
    width: 30px;
    margin: 5px 5px 0 auto;
    outline: none;
    transition: all .4s;
}

.checkout-modal .btn {
    display: block;
    width: 100%;
    padding: 0 20px;
}

.checkout-modal .btn-primary:hover {
    background-color: #ff222f;
    color: #fff;
}

.checkout-modal .btn-primary.focus,
.checkout-modal .btn-primary:focus,
.checkout-modal .btn-primary.disabled,
.checkout-modal .btn-primary:disabled,
.checkout-modal .btn-primary:not(:disabled):not(.disabled).active:focus,
.checkout-modal .btn-primary:not(:disabled):not(.disabled):active:focus,
.checkout-modal .show > .btn-primary.dropdown-toggle:focus,
.checkout-modal .btn-primary:not(:disabled):not(.disabled).active,
.checkout-modal .btn-primary:not(:disabled):not(.disabled):active,
.checkout-modal .show > .btn-primary.dropdown-toggle {
    background-color: #ff222f;
    border-color: #ff222f;
    color: #ffff;
    box-shadow: none;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, .5);
}

.checkout-modal .hint {
    padding: 20px 0;
    text-align: center;
    font-size: 14px;
}

.checkout-modal .hint img {
    vertical-align: bottom;
    margin: 0 auto 20px;
    width: 50px;
    display: block;
}

.carousel-control-prev-icon {
    background: url('../../images/icons/arrow-left.svg');
    background-size: 30px;
}

.carousel-control-next-icon {
    background: url('../../images/icons/arrow-right.svg');
    background-size: 30px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    width: 30px;
    height: 30px;
}

.carousel-item {
    background-position: 50%;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 30.46%;
}

.age-gate {
    text-align: center;
}

.age-gate .modal-body {
    padding: 40px 30px;
}

.age-gate h5 {
    color: #4E1E50;
    margin: 0 0 30px 0;
    font-size: 22px;
    line-height: 28px;
}

.age-gate img {
    margin: 0 auto 30px;
    display: block;
    height: 150px;
}

.age-gate .btn {
    margin: 0 5px;
    min-width: 130px;
}

.modal-backdrop {
    background-color: #4E1E50;
}

.custom-calendar header {
    position: initial;
    padding: 0;
}

.custom-calendar.vdp-datepicker__calendar .cell.selected {
    background-color: #461754;
    color: #fff;
}

.custom-calendar.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.custom-calendar.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.custom-calendar.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
    border: 1px solid #461754;
}

.grecaptcha-badge {
    bottom: 95px !important;
    display: none;
}

.page-content .select2-container--bootstrap4 .select2-selection {
    min-height: 44px;
    border-color: #e7e6e6;
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__rendered {
    line-height: calc(2.1em + .75rem);
}

.newsletter .row .alert {
    width: 100%;
}

.slider-module .owl-carousel .carousel-block {
    position: absolute;
    right: 15%;
    bottom: 25%;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 18px;
    color: #fff;
    text-align: center;
}

.slider-module .owl-carousel .carousel-block h1 {
    color: #E5BA5F;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 11px;
}

.slider-module .owl-carousel .carousel-block h2 {
    color: white;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 38px;
    font-weight: bold;
    line-height: 45px;
    margin-bottom: 23px;
}

.slider-module .owl-carousel .carousel-block p {
    color: white;
    margin: 0 auto 22px auto;
    width: 300px;
    font-size: 15px;
    line-height: 23px;
}

.slider-module .owl-carousel .owl-dots {
    position: absolute;
    left: 50%;
    margin-left: -60px;
    bottom: 0;
    margin-bottom: 27px;
}

.slider-module .owl-theme .owl-dots .owl-dot span {
    width: 14px;
    height: 14px;
    margin: 5px 5px;
    background: #AD9587;
}

.owl-theme .owl-dots .owl-dot {
    outline: none;
}

.slider-module .owl-theme .owl-dots .owl-dot.active span {
    border: 2px solid white;
    background: transparent;
}

.slider-module .owl-carousel .owl-dots button {
    outline: none;
}

.slider-module .owl-carousel .owl-nav {
    margin: 0;
}

.slider-module .owl-carousel .owl-nav button {
    position: absolute;
    top: 50%;
    margin-top: -32px;
    width: 47px;
    height: 47px;
    border-radius: 0;
}

.slider-module .owl-carousel .owl-nav button span {
    font-size: 0;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    display: block;
    width: 16px;
    height: 16px;
}

.slider-module .owl-carousel .owl-nav button.owl-next {
    right: 0;
    margin-right: 20px;
    background: #493C34;
}

.slider-module .owl-carousel .owl-nav button.owl-next:hover {
    background: #493C34;
    opacity: 0.7;
}

.slider-module .owl-carousel .owl-nav button.owl-next span {
    left: 10px;
    transform: rotate(225deg);
    position: relative;
}

.slider-module .owl-carousel .owl-nav button.owl-prev {
    left: 0;
    background: #2A211E;
    margin-left: 20px;
}

.slider-module .owl-carousel .owl-nav button.owl-prev:hover {
    opacity: 0.7;
    background: #2A211E;
}

.slider-module .owl-carousel .owl-nav button.owl-prev span {
    transform: rotate(45deg);
    position: relative;
    left: 18px;
}

.slider-module .owl-carousel .owl-nav button span {
    color: white;
    font-size: 0;
}

a.btn.buy {
    background: #E30613;
    color: white;
    padding: 0 50px;
    position: relative;
    z-index: 1;
}

a.btn.buy:hover {
    color: #E30613;
    background: white;
}

/*two-banners-module*/

.two-banners-module {
    padding: 70px 0;
    position: relative;
}

.two-banners-module .module-banner {
    position: relative;
}

.two-banners-module .module-banner a.gift-box {
    position: relative;
    width: 100%;
    display: block;
}

.two-banners-module img.left-decoration {
    position: absolute;
    left: 0;
    width: 180px;
    top: 0;
    margin-top: 10px;
    margin-left: -31px;
    z-index: 1;
}

.two-banners-module img.right-decoration {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: -144px;
    width: 180px;
    z-index: 1;
}

.two-banners-module .module-banner:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .0)), to(rgba(0, 0, 0, .4)));
    background-image: linear-gradient(rgba(0, 0, 0, .0), rgba(0, 0, 0, .4));
    pointer-events: none;
    -webkit-transition: all .5s;
    transition: all .5s;
}

.two-banners-module .module-banner:hover:before {
    height: 100%;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .0)), to(rgba(0, 0, 0, .7)));
    background-image: linear-gradient(rgba(0, 0, 0, .0), rgba(0, 0, 0, .7));
    z-index: 1;
}

.two-banners-module .module-banner .module-banner-choice {
    position: absolute;
    bottom: 15%;
    margin-left: 55px;
    margin-top: -14px;
}

.two-banners-module .module-banner .module-banner-choice h3 {
    text-align: right;
}

.two-banners-module .module-banner .module-banner-choice.right-banner {
    right: 0;
    margin-right: 55px;
}

.two-banners-module .three-banners .module-banner .module-banner-choice {
    bottom: 50% !important;
    top: auto;
    left: 0;
    text-align: center !important;
    margin: 0 0 -22px 0 !important;
    width: 100%;
    padding: 0 !important;
}

.two-banners-module .three-banners .module-banner .module-banner-choice .btn.buy {
    padding: 0 15px;
    min-width: 144px;
}

/*holiday-offers*/

.holiday-offers {
    padding: 40px 0 33px 0;
    position: relative;
}

.holiday-offers h3 {
    color: #000000;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 32px;
    font-size: 28px;
    line-height: 34px;
    font-family: FSerProB, sans-serif;
    font-weight: 700;
}

.holiday-offers .gift-image {
    position: absolute;
    left: 0;
    top: 50%;
    width: 475px;
    margin-left: -331px;
    margin-top: -220px;
    z-index: 2;
}

.holiday-offers .category-choice {
    text-align: center;
    padding: 0 105px;
    margin-bottom: 30px;
}

.holiday-offers .category-choice button {
    display: block;
    border: 1px solid #D2D2D2;
    padding: 8px 0;
    color: #4E1E50;
    cursor: pointer;
    width: 100%;
    outline: none;
    background: white;
}

.holiday-offers .category-choice button:hover {
    border: 1px solid #4E1E50;
}

.holiday-offers .category-choice button.active {
    border: 1px solid #4E1E50;
}

.holiday-offers .product-article {
    overflow: hidden;
    position: relative;
    -webkit-transition: all .4s;
    transition: all .4s;
}

.holiday-offers .product-article .product-info:hover {
    margin-bottom: 0;
}

.holiday-offers .product-article .product-image {
    overflow: hidden;
    position: relative;
    -webkit-transition: all .4s;
    transition: all .4s;
    z-index: 3;
}

.holiday-offers .product-article .product-image a {
    display: block;
    background: #FCFCFC;
    padding: 13px 30px;
    position: relative;
    text-align: center;
}

.holiday-offers .product-article .product-image a img.new-icon {
    position: absolute;
    left: 0;
    max-width: 35px;
    margin-left: 14px;
    top: 0;
    margin-top: 20px;
}

.holiday-offers .product-article .product-info {
    z-index: 4;
    -webkit-transition: all .4s;
    transition: all .4s;
    position: relative;
    background: white;
    overflow: hidden;
}

.holiday-offers .product-article .product-info:hover {
    -ms-transform: translateY(-80px);
    -webkit-transform: translateY(-80px);
    transform: translateY(-80px);
}

.holiday-offers .product-article .product-info .product-action {
    height: 0;
    transition: all .2s;
    opacity: 0;
}

.holiday-offers .product-article .product-info:hover .product-action {
    position: inherit;
    opacity: 1;
    height: 115px;
}

.holiday-offers .product-article .product-info .product-name {
    font-size: 12px;
    line-height: 20px;
    padding: 13px 15px 20px 15px;
    font-weight: lighter;
    height: 96px;
    margin: 0;
    position: relative;
    font-family: FSerProB, sans-serif;
    color: #000;
    text-align: center;
}

.holiday-offers .product-article .product-info .product-name:after {
    content: "";
    width: 30px;
    height: 1px;
    background-color: #bbb;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -15px;
}

.holiday-offers .product-article .product-info .product-desk {
    text-align: center;
    color: #6a6665;
    display: -webkit-box;
    display: flex;
    font-size: 12px;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    justify-content: center;
    min-height: 50px;
    padding-top: 22px;
}

.holiday-offers .product-article .product-info .product-prices {
    text-align: center;
    padding: 17px 0 15px 0;
    background: white;
}

.holiday-offers .product-article .product-info .product-prices span {
    color: #000000;
}

.holiday-offers .product-article .product-info .product-action a {
    display: block;
    width: 100%;
    padding: 0;
    text-transform: none;
    font-size: 13px;
}

.holiday-offers .product-article .product-info .product-action a.buy {
    margin-bottom: 10px;
}

.holiday-offers .product-article .product-info .product-action a.view {
    display: block;
    text-align: center;
    width: 100%;
    padding: 11px 0 10px 0;
    border: 1px solid #461754;
    color: #461754;
}

.holiday-offers .product-article .product-info .product-action a.view:hover {
    background: #461754;
    color: white;
}

.holiday-offers .see-more {
    position: relative;
}

.holiday-offers .see-more .info-link {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.holiday-offers .product-article.see-more img.bgr-effect {
    position: absolute;
    right: 0;
    height: 100%;
    bottom: 0;
}

.holiday-offers .product-article.see-more .more-info {
    color: white;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    z-index: 100;
    top: 50%;
    font-size: 15px;
    line-height: 26px;
    margin-top: -39px;
    padding: 0 18px;
}

.holiday-offers .product-article.see-more a {
    display: block;
    position: absolute;
    z-index: 100;
    bottom: 0;
    right: 0;
    width: 42px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.holiday-offers .product-article .product-info.tequila .product-name {
    padding: 13px 45px 20px 45px;
}

/*product-slide-module*/

.product-slide-module {
    background: #FFE8CF;
    position: relative;
    padding: 55px 0;
}

.product-slide-module .left-mask {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
}

.product-slide-module .products-list .product-item {
    background: #fff;
    padding: 5px;
    height: 440px
}

.product-slide-module .products-list .product-item .product-action {
    opacity: 0;
    display: none;
}

.product-slide-module .products-list .product-item:hover .product-action {
    opacity: 1;
    display: block;
}

.product-slide-module .product-labels {
    top: 5px;
    left: 5px;
}

.product-slide-module .add-fav {
    top: 5px;
    right: 5px;
}

.product-slide-module.holiday-offers .product-article .product-image a {
    padding: 13px 28px 26px 28px;
}

.product-slide-module h2 {
    color: #471755;
    text-transform: uppercase;
    font-size: 34px;
    line-height: 60px;
    width: 476px;
    margin: 64px 0 0 100px;
}

.product-slide-module h3 {
    margin-top: 60px;
    font-size: 22px;
    line-height: 30px;
}

.product-slide-module .product-info {
    transition: all .4s;
}

.product-slide-module .wine-img img {
    width: 250px;
}

.product-slide-module .owl-carousel .owl-nav button {
    position: absolute;
    top: 38%;
    width: 20px;
    height: 47px;
    border-radius: 0;
}

.product-slide-module .owl-carousel .owl-nav button span {
    font-size: 0;
    border-left: 2px solid #A8A8A8;
    border-bottom: 2px solid #A8A8A8;
    display: block;
    width: 16px;
    height: 16px;
}

.product-slide-module .owl-carousel .owl-nav button.owl-next {
    right: 0;
    margin-right: -22px;
}

.product-slide-module .owl-carousel .owl-nav button.owl-next:hover {
    opacity: 0.7;
}

.product-slide-module .owl-carousel .owl-nav button.owl-next span {
    left: 10px;
    transform: rotate(225deg);
    position: relative;
}

.product-slide-module .owl-carousel .owl-nav button.owl-prev {
    left: 0;
    margin-left: -48px;
}

.product-slide-module .owl-carousel .owl-nav button.owl-prev:hover {
    opacity: 0.7;
}

.product-slide-module .owl-carousel .owl-nav button.owl-prev span {
    transform: rotate(45deg);
    position: relative;
    left: 18px;
}

.product-slide-module .owl-carousel .owl-nav button span {
    color: #A8A8A8;
    font-size: 0;
}

.product-slide-module .owl-carousel .owl-dots {
    display: none;
}

.product-slide-module .see-more {
    text-align: center;
    margin-top: 35px;
}

.product-slide-module .see-more a:hover {
    background: transparent;
    color: #E30613;
    border: 1px solid #E30613;;
}

.product-slide-module .decoration {
    position: absolute;
    top: 0;
    left: 50%;
    width: 176px;
    z-index: 1;
    margin-left: -78px;
    margin-top: -35px;
}

.product-slide-module .star-decoration {
    position: absolute;
    left: 0;
    width: 137px;
    top: 21%;
}

.product-slide-module .gift-box {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 130px;
    margin-bottom: -137px;
    z-index: 1;
}

/*food-and-wine*/

.holiday-offers .star-decor {
    position: absolute;
    top: 0;
    width: 146px;
    right: 0;
    margin-top: -40px;
}

.holiday-offers .star-decor-sec {
    position: absolute;
    bottom: 0;
    z-index: 1;
    left: 57%;
    width: 140px;
    margin-left: -73px;
    margin-bottom: -40px;
}

/*product-slide-module copied*/

.product-slide-module .gift-box-sec {
    position: absolute;
    bottom: 0;
    left: 26%;
    margin-bottom: -40px;
    z-index: 1;
    width: 145px;
}

.product-slide-module .star-sec {
    position: absolute;
    bottom: 0;
    right: 7%;
    width: 110px;
    margin-bottom: -27px;
}

/*.product-slide-module.fourth*/

.product-slide-module .left-mask-2 {
    position: absolute;
    left: 0;
    width: 325px;
    top: 9%;
    margin-left: -234px;
}

.product-slide-module .right-mask {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
}

/*newsletter.gift*/

.newsletter.gift {
    padding: 80px 0 60px 0;
    background: white;
    border: none;
    position: relative;
    margin-bottom: 0;
}

.newsletter.gift .container {
    position: initial;
}

.newsletter.gift:before {
    display: none;
}

.newsletter.gift:after {
    display: none;
}

.newsletter.gift h3 {
    font-family: 'FSerProB', sans-serif;
    color: #FFFFFF;
    text-transform: uppercase;
    text-align: center;
    font-size: 24px;
    padding: 0 232px;
    line-height: 37px;
    margin-bottom: 18px;
    margin-top: 25px;
}

.newsletter.gift p {
    font-size: 14px;
    line-height: 24px;
    padding: 0 170px;
    opacity: 1;
}

.newsletter.gift .news-cont {
    padding: 0 120px;
}

.newsletter.gift .newsletter-form {
    background-size: cover;
    padding: 70px 0;
}

.newsletter.gift .newsletter-star-bgr {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 38px;
}

.newsletter.gift form button {
    background: #E81E25;
    height: 43px;
    color: white;
    padding: 3px 0;
    width: 160px;
    font-size: 11px;
}

.newsletter.gift form button:hover {
    border: 1px solid white;
    background: transparent;
    letter-spacing: 2px;
}

.newsletter.gift form .form-group .checkmark2 {
    position: absolute;
    height: 21px;
    width: 21px;
    background-color: white;
    border-radius: 0;
    border: 0.5px solid #BBC1C5;
    top: 0;
    left: 0;
    margin-left: 0;
    appearance: none;
    -webkit-border-image: none;
    -webkit-appearance: none;
    margin-top: -1px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.newsletter.gift .form-group .checkmark2:after {
    content: "";
    position: absolute;
    display: none;
    -webkit-appearance: none;
    appearance: none;
}

/* Show the checkmark when checked */
.newsletter.gift .form-group .form-check2 input:checked ~ .checkmark2:after {
    display: block;
    -webkit-appearance: none;
    appearance: none;
}

/* Style the checkmark/indicator */
.newsletter.gift .form-group .form-check2 .checkmark2:after {
    left: 7px;
    top: 4px;
    width: 6px;
    height: 10px;
    border: solid #163746;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-appearance: none;
    appearance: none;
}

.newsletter.gift .form-group.checkbox2 {
    margin-left: 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    margin-top: 14px;
}

.newsletter.gift .form-group.checkbox2 input {
    position: absolute;
    left: 0;
    top: 5px;
    margin-left: 5px;
    z-index: -2;
}

.newsletter.gift .form-group.checkbox2 label {
    color: white;
    font-size: 14px;
    line-height: 19px;
    display: inline-block;
    -webkit-appearance: none;
    appearance: none;
    padding-left: 28px;
}

/*decorated-footer-module*/

.decorated-footer-module {
    padding: 65px 0;
    position: relative;
}

.decorated-footer-module p {
    text-align: center;
    color: #242322;
    font-size: 12px;
    margin-bottom: 0;
}

.decorated-footer-module .wine-decor {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 260px;
}

.decorated-footer-module .footer-bgr-mask {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 300px;
}

.decorated-footer-module .footer-decor-2 {
    position: absolute;
    right: 20%;
    bottom: 0;
    width: 180px;
}

.decorated-footer-module .footer-decor-3 {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 290px;
    margin-bottom: -65px;
}

.intro .intro-items .our-logo {
    margin-bottom: 35px;
    display: flex;
    align-items: flex-start;
}

.intro .intro-items {
    position: relative;
    z-index: 20;
}

.intro .intro-items .our-logo span {
    text-transform: uppercase;
    display: table;
    font-size: 16px;
    margin-top: 5px;
    font-family: 'FSerProB', sans-serif;
    color: #2D2A2A;
}

.intro .intro-items .our-logo img {
    max-width: 234px;
    margin: 0 17px;
}

#flash-messages-wrapper {
    position: relative;
    float: right;
    width: 100%;
}

#flash-messages-container {
    position: relative;
    z-index: 2 !important;
    width: 100%;
    padding-top: 20px;
}

.alert-success {
    margin-bottom: 50px;
}

.container .message-div {
    text-align: center;
    padding: 12px 0;
    background-color: #461754;
    text-decoration: solid;
    color: white;
    font-size: 16px;
    margin: 15px 0 15px 0;
}

nav .menu .menu-item.bottom-menu {
    padding: 18px 40px 18px 15px;
    border-bottom: 1px solid #7a4e7b;
    color: #fff;
    background: #390E44;
    text-transform: uppercase;
    font-size: 16px;
}


/*mystery-box-module*/

.mystery-box-module {
    padding: 65px 0 70px 0;
    background: #FAF7FA;
    position: relative;
}

.mystery-box-module h3 {
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 10px;
    font-size: 23px;
    line-height: 48px;
    margin-top: 35px;
}

.mystery-box-module .mask-gr {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
}

.mystery-box-module .mystery-star {
    position: absolute;
    left: 0;
    margin-top: -205px;
    top: 50%;
    z-index: 1;
    width: 280px;
    margin-left: -112px;
}

.mystery-box-module .mystery-box {
    position: absolute;
    bottom: 0;
    width: 120px;
    right: 0;
    margin-bottom: -64px;
    z-index: 1;
}

.mystery-box-module ul li {
    position: relative;
    padding: 27px 0 0 42px;
    color: #000000;
    font-family: FedraSerifAPro, sans-serif;
}

.mystery-box-module ul li .list-logo-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 46px;
    text-align: center;
    padding: 0 14px 0 0;
    margin-top: 31px;
}

.mystery-box-module ul li:nth-child(1) .list-logo-image {
    margin-top: 28px;
}

.mystery-box-module ul li img {
    max-height: 36px;
}

.mystery-box-module ul li span {
    display: block;
    font-family: 'FedraSansPro', sans-serif;
    color: #6A6665;
    font-size: 12px;
}

.mystery-box-module .mystery-box-img {
    display: flex;
    align-items: flex-end;
}

.mystery-box-module .mystery-box-img .bottle img {
    width: 180px;
    margin-bottom: 15px;
    margin-right: 15px;
}

.mystery-box-module .mystery-box-img .box-img {
    position: relative;
}

.mystery-box-module .mystery-box-img .box-img h3 {
    position: absolute;
    color: #E30613;
    top: 25%;
    font-size: 40px;
    text-transform: CAPITALIZE;
    left: 50%;
    margin-left: -133px;
    margin-top: -40px;
}

.mystery-box-module .mystery-box-img .box-img img {
    width: 405px;
}

.mystery-box-module .mystery-box-img .wine-count {
    position: absolute;
    background: white;
    border-radius: 100px;
    bottom: 0;
    width: 96px;
    height: 96px;
    border: 1px solid #E81E25;
    left: 0;
    margin-left: -48px;
}

.mystery-box-module .mystery-box-img .wine-count span {
    color: #E81E25;
    font-size: 55px;
    display: block;
    text-align: center;
    margin-top: -5px;
}

nav .menu .bottom-menu span {
    display: block;
    text-transform: none;
    font-size: 15px;
    margin-top: 3px;
    line-height: 24px;
}

nav .menu .bottom-menu ul li a {
    display: block;
    text-transform: none;
    font-size: 15px;
    margin-top: 3px;
    line-height: 24px;
    color: #fff;
}

.profile-address .profile-content .magnum-offer {
    width: 100%;
    overflow-x: auto;
}

.profile-address .profile-content .magnum-offer .magnum-offer-cont {
    min-width: 1100px;
}

/*magnum-offer*/

.magnum-offer {
    padding-bottom: 100px;
}

.magnum-offer .info-title {
    padding: 10px 20px;
}

.magnum-offer .info-title h5 {
    font-family: 'FedraSansPro', sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
}

.magnum-offer .info-title h5.level {
    text-align: left;
}

.magnum-offer .magnum-offer-cont {
    border: 1px solid #D9D9D9;
    background: #FBFAF9;
    border-bottom: none;
}

.magnum-offer .magnum-offer-cont .magnum-offer-block {
    border-bottom: 1px solid #D9D9D9;
    padding: 30px 20px;
}

.magnum-offer .magnum-offer-cont .magnum-offer-block .person-type {
    color: #000;
    font-family: 'FSerProBold', sans-serif;
    font-size: 18px;
}

.magnum-offer .magnum-offer-cont .magnum-offer-block .user-type-image {
    display: block;
    margin: 0 auto;
}

.magnum-offer .magnum-offer-cont .magnum-offer-block span {
    display: block;
    color: #000000;
}

.magnum-offer .loan,
.magnum-offer .discount {
    width: 110px;
    text-align: center;
}

.magnum-offer h5.loan,
.magnum-offer h5.discount {
    text-align: center;
}

.magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift {
    text-align: center;
}

.magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift img {
    margin-right: 5px;
}

.magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift span {
    display: inline-block;
    max-width: 100px;
    text-align: left;
    vertical-align: middle;
}

/*SUBSCRIBE*/

.subscribe-land {
    position: relative;
    min-height: 1210px;
    background: url("../../images/subscribe2.png") center bottom no-repeat, linear-gradient(0deg, rgba(254, 254, 254, 1) 62%, rgba(239, 239, 239, 1) 79%);
    margin-bottom: -1px;
}

.subscribe-land .left-shape {
    position: absolute;
    left: 0;
    top: 0;
}

.subscribe-land .right-shape {
    position: absolute;
    right: 0;
    bottom: 30%;
}

.subscribe-land .heading {
    font-size: 38px;
    line-height: 57px;
}

.subscribe-land p {
    font-family: "FedraSansProBook", sans-serif;
    margin-bottom: 25px;
    text-align: center;
}

.subscribe-steps .subscribe-step span {
    display: block;
    background: #E81E25;
    width: 50px;
    height: 50px;
    color: #fff;
    text-align: center;
    line-height: 48px;
    border-radius: 100px;
    font-size: 24px;
    font-family: "FedraSansProBook", sans-serif;
}

.subscribe-steps .subscribe-step .step-text {
    font-family: "FedraSansProBook", sans-serif;
    font-size: 16px;
    color: #000000;
}

.subscribe-land form input {
    border-color: #AFAFAF;;
}

.subscribe-land form .form-group.checkbox {
    display: table;
    /*margin: 0 auto;*/
}

.subscribe-land form .form-group.checkbox label:before,
.subscribe-land form .form-group.checkbox label:after {
    margin-top: 2px;
}

.subscribe-land p {
    font-size: 16px;
}

.subscribe-land .bottom-desc {
    text-align: center;
    margin: 0;
    display: block;
    width: 100%;
}

.subscribe-land .bottom-desc p {
    margin: 15px 0 20px 0;
}

footer.bg-transparent {
    background: transparent !important;
}

.loyalty-card-block {
    box-shadow: 0 7px 10px 0 rgba(162, 162, 162, 0.27),
    0 0 1px 0 rgba(144, 144, 144, 0.29);
    display: table;
    border-radius: 16px;
    margin-top: 30px;
}

.loyalty-card-block .card-info {
    background: url("../../images/loyalty-card-bgr.png") center no-repeat;
    background-size: cover;
    height: 320px;
    width: 570px;
    color: #fff;
    padding: 40px 15px 40px 185px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px 0 0 16px;
}

.loyalty-card-block .card-info .title {
    font-weight: bold;
    font-size: 25px;
    font-family: 'FSerProBold', sans-serif;
}

.loyalty-card-block .barcode-block {
    width: 100%;
    margin: 0 auto;
}

.loyalty-card-block .user-info-block img {
    width: 28px;
}

.loyalty-card-block .user-info-block .person-type {
    margin-top: -8px;
}

.loyalty-card-block .user-info-block h5 {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
}

.loyalty-card-block .barcode-info {
    width: 200px;
    height: 100%;
    text-align: center;
    padding: 30px 25px;
    border-radius: 0 16px 16px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.loyalty-card-block .barcode-info .card-number {
    color: #461754;
    font-weight: 600;
    font-size: 20px;
    font-family: "Fedra-SansB", sans-serif;
}

.loyalty-card-block .barcode-info a {
    color: #461754;
    font-weight: 600;
    font-size: 18px;
    font-family: "Fedra-SansB", sans-serif;
}

.thank-you-embed-video {
    max-width: 100%;
    width: 900px;
    height: 506px;
}

.page-content .catalogue-iframe {
    width: 100%;
    height: 1000px;
}

.product .gallery .desktop-image {
    width: 620px;
    height: auto;
    margin-right: 10px;
}

/* GALLERY start */

.media-gallery {
    /*max-width: 567px;*/
    /*margin: 5rem auto;*/
    background: white;
    margin: 0 auto
}

.media-gallery img {
    object-fit: cover;
    width: 100%
}

.media-gallery .lSGallery {
    display: inline-flex
}

.media-gallery .lSGallery li {
    border-radius: 0px !important
}

.media-gallery .lSGallery li.active {
    border: 1px solid #242423
}

/* GALLERY END */

.sommelier-recommendations {
    background: #fbfaf9;
    padding: 20px 10px;

}

.sommelier-recommendations h1 {
    margin: 0 0 25px;
    color: #461754;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    border-bottom: 1px solid #E3E2E2;
    padding-bottom: 15px;
}

.sommelier-recommendations .sommelier-method label {
    border: 1px solid #E3E2E2;
    padding: 15px;
    cursor: pointer;
    background: transparent;
    width: 100%;
    display: block;
    margin-bottom: 30px;
}

.sommelier-recommendations .sommelier-method label:hover {
    border-color: #461754;
}

.sommelier-recommendations .sommelier-method input:checked + label {
    background: #fff;
    border-color: #461754;
}


.sommelier-recommendations .sommelier-method input {
    position: absolute;
    opacity: 0;
}

.sommelier-recommendations .sommelier-method label {

}

.sommelier-recommendations .sommelier-method .sommelier-img img {
    display: block;
    width: 130px;
    border-radius: 100px;
    margin: 0 auto 10px auto;
}

.sommelier-recommendations .sommelier-method .sommelier-name {
    color: #000;
    font-weight: 700;
}

.sommelier-recommendations .sommelier-method .sommelier-desc {
    font-size: 13px;
}

.sommelier-recommendations .btn {
    min-width: 200px;
    letter-spacing: 2px;
    font-size: 14px;
}

.instant-open {
    overflow: hidden;
}

.instant-open .algolia-search {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    background: #fff;
    z-index: 99;
    margin-top: 50px;
    padding: 70px 100px 230px 70px;
    overflow-y: auto;
    height: calc(100vh - 50px);
}

.instant-open header .logo {
    padding-right: 16.5px;
    transition: none;
}

.instant-open header .user-widgets {
    padding-right: 16.5px;
}

.algolia-search .ais-SearchBox {
    max-width: 600px;
    margin: 0 auto 60px auto;
}

.algolia-search .ais-SearchBox-input {
    height: 44px;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #e3e2e2;
}

.algolia-search #filters {
    display: block;
    position: sticky;
    top: 95px;
    margin-top: 96px;
    max-height: 70vh;
    overflow-y: auto;
    padding-right: 20px;
}

.algolia-search #filters::-webkit-scrollbar {
    width: 0.3rem;
    background: #e3e2e2;
}

.algolia-search #filters::-webkit-scrollbar-track {
    border-radius: 10px;
}

.algolia-search #filters::-webkit-scrollbar-thumb {
    background: #461754;
    border-radius: 10px;
    cursor: pointer;
}


.algolia-search #filters h4 {
    font-size: 14px;
    line-height: 20px;
    color: #222;
    font-family: "FedraSansPro", sans-serif;
    position: relative;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.instant-open #category-filter, .instant-open #manufacturer-filter, .instant-open #variety-filter, .instant-open #country-filter {
    /*border-bottom: 1px solid #e3e2e2;*/
    padding: 5px 0 20px 0;
    margin-bottom: 25px;
}

#filters label {
    position: relative;
    padding: 0 36px 0 12px;
    margin: 18px 0;
    color: #afafaf;
    display: block;
}

#filters .ais-RefinementList-item--selected label {
    color: #e30613;
}

#filters .ais-RefinementList-item--selected label:after {
    content: "";
    background: url("../../images/icons/input-check.svg") 50% no-repeat;
    background-size: 24px;
    position: absolute;
    top: 0;
    left: 0;
    width: 19px;
    height: 20px;
}

#filters label:before {
    content: "";
    width: 18px;
    height: 18px;
    border: 1px solid #e3e2e2;
    display: block;
    position: absolute;
    top: 2px;
    left: 0;

}

#filters input {
    opacity: 0;
}

#filters [class^=ais-] {
    font-size: 14px;
}

#filters button {
    background: transparent;
    border: 1px solid #e3e2e2;
    width: 100%;
    color: #afafaf;
}

#filters button:hover, #filters button:focus, #filters button:active {
    border-color: #e30613;
    color: #e30613;
    border-width: 1px !important;
    outline: none;
    box-shadow: none;
}

#filters .ais-RefinementList-count {
    position: absolute;
    right: 0;
    top: 0;
    -webkit-box-align: center;
    align-items: center;
    padding: 0 8px;
    font-size: 12px;
    line-height: 14px;
    border: 1px solid #d6d6e7;
    box-sizing: border-box;
    border-radius: 93px;
    height: 18px;
    background: #f5f5fa;
}

#hits .ais-Hits-item {
    border: none;
    box-shadow: none;
    padding: 0;
    text-align: center;
    margin-top: 60px;
    width: calc(16.66% - 1rem);
}

#hits .ais-Hits-item img {
    width: 100% !important;
}

#hits .ais-Hits-item h3 {
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
    min-height: 76px;
    margin: 0 0 30px;
    position: relative;
    font-family: FSerProB, sans-serif;
    color: #000;
    display: -webkit-box;
    display: flex;
    padding: 10px 0 0;
    -webkit-box-align: start;
    align-items: start;
    -webkit-box-pack: center;
    justify-content: center;
}

#hits .ais-Hits-item h3:after {
    content: "";
    width: 30px;
    height: 1px;
    background-color: #bbb;
    position: absolute;
    bottom: -18px;
    left: 50%;
    margin-left: -15px;
}

#hits .ais-Hits-item div div {
    margin: 10px 0 15px;
    font-weight: 700;
    color: #000;
    font-size: 16px;
}


.algolia-search #pagination {
    margin: 0 auto 0 auto;
    display: table;
}

.instant-open .algolia-search #pagination {
    margin-top: 30px;
}

.algolia-search #pagination ul {
    flex-wrap: wrap;
}

.algolia-search #pagination ul li {
    display: inline-block;
    font-size: 12px;
}

.algolia-search #pagination ul li a,
.algolia-search #pagination ul li span {
    font-size: 15px;
    line-height: 26px;
    display: block;
    width: 38px;
    height: 38px;
    text-align: center;
    margin: 0 6px;
    border: 1px solid #e3e2e2;
    border-radius: 100%;
}

.algolia-search #pagination ul li a:hover {
    border: 1px solid #461754;
    color: #461754;
    background: transparent;
}

.algolia-search #pagination ul li.ais-Pagination-item--selected a {
    border: 1px solid #461754;
    background-color: #461754;
    color: #fff;

}

.algolia-search .btn-close {
    display: none;
}

.instant-open .algolia-search .btn-close {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    border: none;
    color: #000;
    outline: none;
    background: transparent;
    font-size: 25px;
    font-family: cursive;
    text-transform: uppercase;
    z-index: 1000;
}

.instant-open .algolia-search .btn-close:hover {
    color: #afafaf;
}

.algolia-search .toggle-filter {
    display: none;
}

.instant-open .algolia-search .toggle-filter {
    display: block;
    height: 44px;
    line-height: 33px;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #e3e2e2;
}