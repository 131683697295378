
@font-face {
    font-family: 'FedraSansPro';
    src: url("../../fonts/FedraSansPro-Book.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: 'FSerProB';
    font-weight: bold;
    src: url("../../fonts/FSerProB-Medium.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: 'FSerProBold';
    font-weight: bold;
    src: url("../../fonts/FedraSerifProBBold.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: 'FSerProBook';
    font-weight: bold;
    src: url("../../fonts/FedraSerifProBBook.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: 'FedraSerifAPro';
    src: url('../../fonts/FedraSerifAPro-Medium.eot');
    src: url('../../fonts/FedraSerifAPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/FedraSerifAPro-Medium.woff2') format('woff2'),
    url('../../fonts/FedraSerifAPro-Medium.woff') format('woff'),
    url('../../fonts/FedraSerifAPro-Medium.ttf') format('truetype'),
    url('../../fonts/FedraSerifAPro-Medium.svg#FedraSerifAPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FedraSansProBook';
    src: url('../../fonts/FedraSansPro-BookLF.eot');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FedraSansProBook';
    src: local('FedraSansPro-BookLF'),
    url('../../fonts/FedraSansPro-Book.woff2') format('woff2'),
    url("../../fonts/FedraSansPro-Book.woff") format('woff'),
    url('../../fonts/FedraSansPro-BookLF.ttf') format('truetype'),
    url('../../fonts/FedraSansPro-BookLF.svg#FedraSansPro-BookLF') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra-SansB';
    src: url('../../fonts/FedraSans-Book.eot');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Fedra-SansB';
    src: local('Fedra Sans Book'), local('FedraSans-Book'),
    url("../../fonts/FedraSans-Book.woff2") format('woff2'),
    url("../../fonts/FedraSans-Book.woff") format('woff'),
    url('../../fonts/FedraSans-Book.ttf') format('truetype'),
    url('../../fonts/FedraSans-Book.svg#FedraSans-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

