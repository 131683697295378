@media only screen and (min-width: 992px) and (max-width: 4000px) {
    .algolia-search .ais-SearchBox .ais-SearchBox-submit {
        left: auto;
        right: 4px;
    }

    .algolia-search .ais-SearchBox .ais-SearchBox-submit .ais-SearchBox-submitIcon {
        width: 22px;
        height: 22px;
        filter: brightness(0) saturate(100%) invert(79%) sepia(6%) saturate(156%) hue-rotate(182deg) brightness(90%) contrast(91%);
    }

    .algolia-search .ais-SearchBox-input {
        padding: 10px 38px 10px 0;
        border: none;
        border-bottom: 2px solid #d9d9d9;
        font-family: FedraSansPro, sans-serif;
    }

    .algolia-search .ais-SearchBox-reset {
       display: none !important;
    }


    .algolia-search .ais-SearchBox-input::placeholder {
        font-size: 14px;
        color: #6A6665;
    }

    .instant-open .algolia-search .btn-close {
        top: 110px;
        position: fixed;
        right: 35px;
        z-index: 99999;
    }

    .instant-open .algolia-search.hint-open .btn-close {
        top: 160px;
    }

    .instant-open .algolia-search {
        margin-top: 0;
        height: 100vh;
        padding-top: 0;
    }

    .instant-open .algolia-search #hits {
        padding-top: 95px;
    }

    .instant-open .algolia-search:before {
        content: "";
        left: 0;
        top: 42px;
        background: #fff;
        width: 100%;
        height: 105px;
        position: fixed;
        z-index: 10;
    }

    .instant-open .algolia-search.hint-open:before {
        top: 79px;
    }

    .algolia-search .ais-SearchBox {
        position: fixed;
        top: 62px;
        left: 0;
        width: 300px;
        margin-left: 15px;
        margin-top: 5px;
        z-index: 9999;
    }

    .algolia-search.hint-open .ais-SearchBox {
        top: 98px;
    }

    .instant-open header .logo,
    .instant-open header .user-widgets {
        position: relative;
        z-index: 999;
    }

    .instant-open header.fixed .algolia-search:before {
        top: 0;
        height: 75px;
    }

    .instant-open.hint-open .algolia-search:before {
        top: 0;
    }

    .instant-open header.fixed .algolia-search .btn-close {
        top: 40px;
    }
    header.fixed .algolia-search .ais-SearchBox {
        top: 0;
    }

}



    @media only screen and (min-width: 1400px) and (max-width: 4000px) {
    .intro h2 {
        font-size: 52px;
        line-height: 60px;
        margin: 0 0 30px 0;
    }


    .page-content .catalogue-iframe{
        width: 100%;
        height: 1000px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1440px) {
    /*.instant-open .algolia-search {*/
    /*    margin-top: -99px;*/
    /*    height: calc(100vh + 99px);*/
    /*    padding: 75px 15px;*/
    /*}*/

    .instant-open .algolia-search:before {
        top: 63px;
    }
    .instant-open .algolia-search.hint-open:before {
        top: 100px;
    }
    .instant-open .algolia-search .btn-close {
        top: 125px;
    }
    .algolia-search .ais-SearchBox {
        top: 82px;
    }

    .instant-open.hint-open .algolia-search:before {
        top: 100px;
    }
    .instant-open.hint-open .algolia-search .btn-close {
        top: 168px;
    }
    .algolia-search.hint-open .ais-SearchBox {
        top: 120px;
    }


}

@media only screen and (min-width: 992px) and (max-width: 1200px) {

    .page-content .catalogue-iframe{
        width: 100%;
        height: 620px;
    }

    .top .top-item {
        margin-right: 23px;
    }

    nav .menu .menu-item {
        margin: 0 18px;
    }

    .loyalty-card-block .card-info {
        height: 260px;
        width: 466px;
        padding: 25px 15px 25px 144px;
    }

    .loyalty-card-block .card-info .title {
        font-size: 23px;
    }

    .loyalty-card-block .barcode-info {
        width: 160px;
        padding: 25px 22px;
    }

    .loyalty-card-block .barcode-info .card-number {
        font-size: 18px;
    }

    .loyalty-card-block .barcode-info a {
        font-size: 16px;
    }
    /*.instant-open .algolia-search {*/
    /*    margin-top: -88px;*/
    /*    height: calc(100vh + 88px);*/
    /*    padding: 100px 50px 200px 50px;*/
    /*}*/

    #hits .ais-Hits-item {
        width: calc(25% - 1rem);
    }

    .algolia-search .ais-SearchBox {
        width: 226px;
    }

    .algolia-search #filters {
        padding-right: 14px;
    }



}

@media only screen and (min-width: 0px) and (max-width: 991px) {
    .algolia-search .btn-close {
        display: none !important;
    }

    .user-widgets .btn-close {
        display: none;
    }

    .instant-open .user-widgets .btn-close {
        display: inline-block;
        border: none;
        background: transparent;
        font-family: cursive;
        font-size: 28px;
        color: #4e5257;
        line-height: 16px;
        margin: -9px -6px 0 0;
        vertical-align: middle;
    }

    .instant-open .user-widgets .widget-item.search-widget img {
        display: none;
    }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {

    .page-content .catalogue-iframe{
        width: 100%;
        height: 420px;
    }

    .thank-you-embed-video {
        max-width: 100%;
        width: 900px;
        height: 350px;
    }

    .page-info {
        padding: 10px 0;
        margin: 0 0 10px 0;
    }

    .space {
        padding: 50px 0;
    }

    .nav-btn {
        z-index: 105;
        width: 30px;
        height: 18px;
        cursor: pointer;
        position: relative;
        top: 5px;
    }

    .nav-btn span {
        display: block;
        height: 4px;
        background-color: #4e5257;
        position: absolute;
        right: 0;
        top: 0;

        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);

        -webkit-transition: .1s ease-in-out;
        -moz-transition: .1s ease-in-out;
        -o-transition: .1s ease-in-out;
        transition: .1s ease-in-out;

        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    .nav-btn span:nth-child(1) {
        width: 100%;
    }

    .nav-btn span:nth-child(2) {
        width: 100%;
        top: 7px;
    }

    .nav-btn span:nth-child(3) {
        width: 100%;
        top: 14px;
    }

    .nav-btn:hover span:nth-child(2) {
        width: 100%;
    }

    .nav-btn:hover span:nth-child(3) {
        width: 100%;
    }

    .nav-btn.open {
        transition-duration: .3s;
    }

    .nav-btn.open span {
        box-shadow: none;
        background-color: #3c5a5d;
        right: -5px;
    }

    .nav-btn.open span:nth-child(1) {
        width: 100%;
        transform: rotate(45deg);
        top: -3px;
    }

    .nav-btn.open span:nth-child(2) {
        opacity: 0;
    }

    .nav-btn.open span:nth-child(3) {
        width: 100%;
        transform: rotate(-45deg);
        top: 18px;
    }

    nav {
        height: 0;
        overflow: hidden;
    }

    nav.open {
        height: 100%;
    }

    header {
        padding: 15px 0 15px 0;
    }

    header.fixed {
        padding: 5px 0 5px 0;
    }

    header .logo {
        margin: 0 auto;
        display: table;
    }

    header .logo img {
        height: 32px;
    }

    header nav {
        display: block;
        width: 100%;
        margin: 0;
    }

    header .menu {
        float: none;
        padding-left: 0;
        padding-bottom: 0;
        max-height: 544px;
        overflow: auto;
        position: relative;
        background-color: #461754;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    nav .menu .menu-item {
        margin: 0;
        display: block;
        position: relative;
    }

    nav .menu .menu-item .dropdown-btn {
        position: absolute;
        right: 0;
        top: 0;
        background-color: #643a66;
        display: block;
        height: 35px;
        width: 35px;
        color: #fff;
        line-height: 35px;
        text-align: center;
        opacity: .7;
        margin: 0;
    }

    nav .menu .menu-item .menu-link {
        padding: 5px 40px 5px 15px;
        display: block;
        border-bottom: 1px solid #7a4e7b;
        color: #fff;
    }

    nav .menu .menu-item:hover .menu-link,
    nav .menu .menu-item.offer-special .menu-link {
        color: #fff;
    }

    nav .menu .menu-item:hover .menu-link:before {
        display: none;
    }

    .mega-menu .mega-menu-cat {
        position: relative;
        padding-left: 15px;
    }

    .mega-menu .mega-menu-cat .mega-menu-list {
        display: none;
    }

    .mega-menu .mega-menu-list.red-dot > li:before {
        display: none;
    }

    .sub-menu {
        display: none;
    }

    nav .menu .sub-menu {
        padding: 0 0 0 15px;
        background-color: initial;
    }

    .mega-menu .mega-menu-list > li,
    .mega-menu .mega-menu-list li {
        padding: 0;
        margin: 0;
        position: relative;
    }

    .mega-menu .mega-menu-list li a,
    .mega-menu .mega-menu-list.red-dot > li > a,
    nav .menu .sub-menu .sub-menu-link {
        font-size: 16px;
        margin: 0;
        line-height: 24px;
        letter-spacing: 0;
        width: initial;
        text-transform: uppercase;
        font-family: 'FedraSansPro', sans-serif;
        font-weight: normal;
        padding: 5px 40px 5px 15px;
        display: block;
        border-bottom: 1px solid #7a4e7b;
        color: #fff;
    }

    .mega-menu .mega-menu-list > li > ul {
        margin: 0;
        display: none;
    }

    .mega-menu .mega-menu-list a {
        padding-left: 25px;
    }

    .dropdown-btn ~ ul {
        position: initial !important;
        left: initial !important;
        right: initial !important;
        top: initial !important;
        border: 0 !important;
        box-shadow: initial !important;
        padding-left: 15px;
    }

    .mega-menu {
        position: initial !important;
        left: initial !important;
        right: initial !important;
        top: initial !important;
        border: 0 !important;
        box-shadow: initial !important;
        padding: initial !important;
        background-color: initial !important;
    }

    .mega-menu h5 {
        font-size: 16px;
        margin: 0;
        line-height: 24px;
        letter-spacing: 0;
        position: relative;
        padding: 5px 40px 5px 15px;
        display: block;
        border-bottom: 1px solid #7a4e7b;
        color: #fff;
    }

    .mega-menu-banner {
        display: none;
    }

    .mega-menu-list {
        margin: 0 0 0 0;
    }

    .heading {
        margin: 0 0 50px 0;
        font-size: 20px;
        line-height: 26px;
    }

    .top .top-item.phone {
        margin-right: 0;
        margin-top: 5px;
    }

    .newsletter .hint {
        padding: 0 80px;
        font-size: 14px;
    }

    .newsletter .btn {
        margin: 15px 0 0 0;
    }

    .blog-list .blog-item h3 {
        margin: 20px 0 5px 0;
    }

    .blog-list .blog-item h3 a {
        font-size: 14px;
        line-height: 20px;
    }

    .intro h2 {
        font-size: 40px;
        line-height: 50px;
    }

    .home .benefits {
        padding: 50px 0 15px;
    }

    .home .benefits .benefit {
        position: relative;
        margin: 0 0 35px 0;
    }

    .home .benefits .benefit h5 {
        padding: 0;
        height: inherit
    }

    .home .benefits .benefit .benefit-img {
        height: 60px;
        margin: 0 0 10px 0;
    }

    .home .benefits .benefit .benefit-img img {
        height: 60px;
        margin: 0 auto;
        display: block;
        max-width: 100px;
    }

    .newsletter .btn {
        margin: 0 0 0 0;
    }

    .product .product-variants {
        padding: 15px 15px 5px 15px;
    }

    .product .product-features {
        padding: 50px 0;
    }

    .product .product-sort {
        margin-bottom: 25px;
    }

    .suitable-foods {
        margin: 20px 0 0 0;
        padding: 20px 0 0 0;
        border-top: 1px solid #e3e2e2;
    }

    .product .assortment {
        padding-top: 50px;
        padding-bottom: 0;
    }

    .similar-products {
        padding-left: 0;
        padding-right: 0;
        padding-top: 50px;
        padding-bottom: 5px;
    }

    .events .event-space {
        margin-bottom: 16px;
    }

    .events .event .event-info .event-meta .slots {
        margin-left: 0;
        display: block;
    }

    .events .event:hover .event-info .btn {
        max-height: 36px;
        line-height: 34px;
        margin-bottom: 20px;
    }

    .events .event .event-info {
        padding: 25px 20px 0;
    }

    .events .view-all {
        margin: 40px auto 0;
    }

    .blog-list .view-all {
        margin: 30px auto 0;
        display: table;
    }

    .product-recently {
        padding-top: 50px;
    }

    .offers .main-offer-text h3 {
        font-size: 18px;
        letter-spacing: 3px;
    }

    .offers .main-offer-text {
        bottom: 18%;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .assortment .assortment-item {
        border-bottom: 30px solid #fff;
    }

    .products-list .product-item {
        margin-bottom: 50px;
    }

    .products-list .product-item .product-action {
        opacity: 1;
    }

    .products-list .product-item:hover .product-info {
        margin: 0 0 0 0;
        transform: translate(0, 0px);
    }

    .products-list.product-recently .product-item .product-prices .price,
    .products-list.product-recently .product-item .product-prices .old-price {
        font-size: 16px;
    }

    .products-list.product-recently .product-item .product-name {
        font-size: 16px;
        line-height: 22px;
    }

    .products-list .product-item:hover .product-img:after {
        margin-left: -70px;
    }

    .special-offers {
        padding-bottom: 0;
    }

    .search {
        max-width: 100%;
        display: none;
        margin: 0 0 15px 0;
    }

    .blog-list .blog-filter {
        margin-left: -5px;
        margin-bottom: 20px;
    }

    .blog-list .blog-filter li:first-of-type {
        margin-left: 5px;
    }

    .blog-list .blog-filter li {
        margin: 0 0 0 5px;
    }

    .blog-list .blog-filter li a {
        padding: 3px 10px;
        margin: 0 0 5px 0;
    }

    .user-widgets .widget-item .icn {
        height: 20px;
    }

    .promo-code {
        margin: 15px 0 15px 0;
        padding:15px;
    }

    footer {
        background-image: none;
        padding: 50px 0 30px;
    }

    footer .footer-menu {
        margin: 0 0 30px 0;
    }

    footer h3 {
        margin: 0 0 10px 0;
    }

    footer ul li {
        margin-bottom: 3px;
    }

    footer .footer-contacts.visit-us li {
        padding-left: 28px;
    }

    footer .footer-contacts li {
        color: #b3b3b3;
        margin-bottom: 20px;
        padding-left: 0;
    }

    footer .footer-logo {
        margin: 10px auto 0;
    }


    .assortment-img img {
        width: 100%;
    }

    .assortment .assortment-item h3 {
        font-size: 14px;
        line-height: 18px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .events .event-block .event-info h3 {
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 5px 0;
    }

    .events .event-block .event-info .event-meta {
        font-size: 14px;
    }

    .events .event-block .event-info .event-meta .slots {
        display: block;
        margin: 0;
    }

    .countries .view-all {
        float: none;
        padding: 11px 0;
        text-align: center;
    }

    .countries .country {
        padding: 10px 3px;
    }

    .products-list .product-item .product-desc {
        color: #6A6665;
        min-height: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /*header.fixed ~ .page {*/
    /*    padding-top: 62px !important;*/
    /*}*/

    .bottles {
        padding: 30px;
    }

    .sticky-top {
        top: 65px;
        z-index: 3;
    }

    .team .person img {
        margin: 0 auto;
        display: block;
    }

    .contacts .contact-info ul li {
        padding: 25px 0 25px 15px;
    }

    .contacts .contact-form {
        padding: 45px 25px 80px 25px;
    }

    .total .product-row .product-name {
        font-size: 12px;
        line-height: 18px;
        margin: 0 0 10px 0;
    }

    .total .product-row .product-old-price {
        text-align: right;
    }

    .products-list .product-item .product-action .btn-secondary-outline {
        display: none;
    }

    .products-list .product-item .product-action .btn-primary {
        margin: 0;
    }

    .profile .profile-content {
        padding: 65px 45px 65px 45px;
    }

    .profile label {
        font-size: 12px;
    }

    .profile .order-details .val {
        margin: 0 0 10px 0;
        line-height: 18px;
    }

    .profile .product-price {
        text-align: left;
        margin: 5px 0 0 0;
        font-weight: bold;
        font-size: 14px;
        color: #000;
    }

    .profile-orders .order-num,
    .profile-content .order-num {
        padding: 0 0 20px 0;
        font-size: 14px;
        line-height: 20px;
    }

    .profile .order-products {
        border-top: 0;
    }

    .profile .order-products .product-row:first-of-type {
        padding-top: 0;
    }

    .profile .order-details {
        margin: 0 0 20px 0;
    }

    .profile .order-products .product-row .product-name {
        margin: 0;
    }

    .product-recently {
        padding: 50px 15px 0 15px;
    }

    .new-offers {
        padding: 50px 15px 0 15px;
    }

    .profile-content .order-row a.btn {
        max-width: 180px;
    }

    .sum ul li span {
        font-weight: bold;
    }

    .gift-package label {
        margin-left: 5px;
        display: inline;
    }

    .order-auth {
        background: none;
    }

    .profile .profile-content .order-row .order-num span {
        display: block;
        width: 100%;
    }

    .profile .profile-content .order-row .order-num div a.btn {
        width: 230px;
    }

    .subscribe-land {
        min-height: 980px;
        background-size: 970px;
    }

    .subscribe-land .heading {
        font-size: 28px;
        line-height: 45px;
    }

    .subscribe-steps .subscribe-step .step-text {
        font-size: 15px;
    }

    .subscribe-land .left-shape,
    .subscribe-land .right-shape {
        max-width: 200px;
    }

    .loyalty-card-block {
        width: 425px;
    }

    .loyalty-card-block .card-info {
        height: 174px;
        width: 302px;
        padding: 18px 15px 18px 70px;
    }

    .loyalty-card-block .card-info .title {
        font-size: 20px;
    }

    .loyalty-card-block .user-info-block img {
        width: 24px;
    }

    .loyalty-card-block .user-info-block h5 {
        font-size: 14px;
        line-height: 21px;
    }

    .loyalty-card-block .barcode-info {
        width: 123px;
        padding: 18px 15px;
    }

    .loyalty-card-block .barcode-info .card-number {
        font-size: 17px;
    }

    .loyalty-card-block .barcode-block img {
        width: 65px;
    }

    .loyalty-card-block .barcode-info a {
        font-size: 14px;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift {
        text-align: center;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift img {
        margin: 0;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift span {
        display: block;
        margin: 5px auto 0 auto;
        text-align: center;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .person-type {
        text-align: center;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .person-type img {
        max-width: 48px;
        max-height: 52px;
        margin: 0 auto 5px auto;
    }

    .instant-open .algolia-search {
        z-index: 999;
        margin-top: 6px;
        padding: 70px 15px;
        height: calc(100vh - 135px);
    }

    #hits .ais-Hits-item {
        width: calc(50% - 1rem);
    }

    .algolia-search #filters {
        top: -20px;
        padding-right: 15px;
    }

}

@media only screen and (min-width: 0px) and (max-width: 767px) {

    .page-content .catalogue-iframe{
        width: 100%;
        height: 300px;
    }

    .thank-you-embed-video {
        max-width: 100%;
        width: 900px;
        height: 250px;
    }

    .cart-list .cart-list .product-old-price {
        text-align: center;
    }

    .cart-list .product-img {
        text-align: center;
    }

    .cart-list .cart-product-price {
        text-align: center;
        display: block;
    }

    .cart-list .product-old-price {
        text-align: center;
    }

    .cart-list .cart-row {
        padding: 15px 0;
    }

    .product-quantity button {
        width: 28px;
    }

    .cart-list .remove-product {
        position: absolute;
        top: 50%;
        left: 5px;
        right: inherit;
        font-size: 16px;
        height: 28px;
        margin: -14px 0 0 0;
    }

    .mega-menu .mega-menu-container {
        overflow: hidden;
    }

    .page-info {
        padding: 10px 0;
        margin: 0 0 0 0;
    }

    .space-bot {
        padding-bottom: 50px;
    }

    .space {
        padding: 50px 0;
    }

    .nav-btn {
        z-index: 105;
        width: 30px;
        height: 18px;
        cursor: pointer;
        position: relative;
        top: 5px;
    }

    .nav-btn span {
        display: block;
        height: 4px;
        background-color: #4e5257;
        position: absolute;
        right: 0;
        top: 0;

        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);

        -webkit-transition: .1s ease-in-out;
        -moz-transition: .1s ease-in-out;
        -o-transition: .1s ease-in-out;
        transition: .1s ease-in-out;

        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    .nav-btn span:nth-child(1) {
        width: 100%;
    }

    .nav-btn span:nth-child(2) {
        width: 100%;
        top: 7px;
    }

    .nav-btn span:nth-child(3) {
        width: 100%;
        top: 14px;
    }

    .nav-btn:hover span:nth-child(2) {
        width: 100%;
    }

    .nav-btn:hover span:nth-child(3) {
        width: 100%;
    }

    .nav-btn.open {
        transition-duration: .3s;
    }

    .nav-btn.open span {
        box-shadow: none;
        background-color: #3c5a5d;
        right: -5px;
    }

    .nav-btn.open span:nth-child(1) {
        width: 100%;
        transform: rotate(45deg);
        top: -3px;
    }

    .nav-btn.open span:nth-child(2) {
        opacity: 0;
    }

    .nav-btn.open span:nth-child(3) {
        width: 100%;
        transform: rotate(-45deg);
        top: 18px;
    }

    nav {
        height: 0;
        overflow: hidden;
    }

    nav.open {
        height: 100%;
    }

    header {
        padding: 5px 0 0 0;
    }

    header .logo {
        margin: 0 auto;
        display: table;
    }

    header .logo img {
        height: 32px;
    }

    header nav {
        display: block;
        width: 100%;
        margin: 0;
    }

    header .menu {
        float: none;
        padding-left: 0;
        padding-bottom: 0;
        overflow: auto;
        position: relative;
        background-color: #461754;
        margin-bottom: 15px;
    }

    nav .menu .menu-item {
        margin: 0;
        display: block;
        position: relative;
    }

    nav .menu .menu-item.active .menu-link {
        color: #fff;
    }

    nav .menu .menu-item.active .menu-link:before {
        display: none;
    }

    nav .menu .menu-item .dropdown-btn {
        position: absolute;
        right: 0;
        top: 0;
        background-color: #643a66;
        display: block;
        height: 35px;
        width: 35px;
        color: #fff;
        line-height: 35px;
        text-align: center;
        opacity: .7;
        margin: 0;
    }

    nav .menu .menu-item .menu-link {
        padding: 5px 40px 5px 15px;
        display: block;
        border-bottom: 1px solid #7a4e7b;
        color: #fff;
    }

    nav .menu .menu-item:hover .menu-link,
    nav .menu .menu-item.offer-special .menu-link {
        color: #fff;
    }

    nav .menu .menu-item:hover .menu-link:before {
        display: none;
    }

    .mega-menu .mega-menu-cat {
        position: relative;
        padding-left: 15px;
    }

    .mega-menu .mega-menu-cat .mega-menu-list {
        display: none;
    }

    .mega-menu .mega-menu-list.red-dot > li:before {
        display: none;
    }

    .sub-menu {
        display: none;
    }

    nav .menu .sub-menu {
        padding: 0 0 0 15px;
        background-color: initial;
    }

    .mega-menu .mega-menu-list > li,
    .mega-menu .mega-menu-list li {
        padding: 0;
        margin: 0;
        position: relative;
    }

    .mega-menu .mega-menu-list li a,
    .mega-menu .mega-menu-list.red-dot > li > a,
    nav .menu .sub-menu .sub-menu-link {
        font-size: 16px;
        margin: 0;
        line-height: 24px;
        letter-spacing: 0;
        width: initial;
        text-transform: uppercase;
        font-family: 'FedraSansPro', sans-serif;
        font-weight: normal;

        padding: 5px 40px 5px 15px;
        display: block;
        border-bottom: 1px solid #7a4e7b;
        color: #fff;
    }

    .mega-menu .mega-menu-list > li > ul {
        margin: 0;
        display: none;
    }

    .mega-menu .mega-menu-list a {
        padding-left: 25px;
    }

    .dropdown-btn ~ ul {
        position: initial !important;
        left: initial !important;
        right: initial !important;
        top: initial !important;
        border: 0 !important;
        box-shadow: initial !important;
        padding-left: 15px;
    }

    .mega-menu {
        position: initial !important;
        left: initial !important;
        right: initial !important;
        top: initial !important;
        border: 0 !important;
        box-shadow: initial !important;
        padding: initial !important;
        background-color: initial !important;
    }

    .mega-menu h5 {
        font-size: 16px;
        margin: 0;
        line-height: 24px;
        letter-spacing: 0;
        position: relative;

        padding: 5px 40px 5px 15px;
        display: block;
        border-bottom: 1px solid #7a4e7b;
        color: #fff;
    }

    .mega-menu-banner {
        display: none;
    }

    .mega-menu-list {
        margin: 0 0 0 0;
    }

    .heading {
        margin: 0 0 50px 0;
        font-size: 20px;
        line-height: 26px;
    }

    .delivery-hint span {
        display: block;
        font-size: 12px;
        line-height: 16px;
        margin: 0;
    }

    .delivery-hint .sep {
        display: none;
    }

    .delivery-hint {
        padding: 4px 0;
    }

    .top .top-item {
        text-align: center;
        display: inline-block;
    }

    .top .top-item.social {
        margin-right: 0;
        float: right;
    }

    .top .top-item:after {
        display: none;
    }

    .top .top-item.phone {
        display: none;
    }

    .top .top-item.vouchers {
        display: none;
    }

    .search {
        max-width: 100%;
        display: none;
        margin: 0 0 15px 0;
    }

    .user-widget .user-nav:after {
        width: 30px;
        left: inherit;
        right: 0;
    }

    .user-widget .user-nav {
        top: 36px;
        left: 0;
        right: inherit;
    }

    .user-widgets .widget-item .cart-sum {
        display: none;
    }

    .user-widgets .widget-item.fav-widget {
        display: none;
    }

    .user-widgets {
        text-align: right;
        padding: 10px 0 15px;
    }

    .cart-widget:after {
        bottom: -16px;
        z-index: 15;
    }

    .cart-widget:hover:after {
        display: none;
    }

    .cart-widget:hover .cart-container {
        display: none;
    }

    .user-widget .user-nav:before,
    .cart-container:before {
        display: none
    }

    .cart-container {
        top: 37px;
    }

    .newsletter .hint {
        padding: 0;
        font-size: 14px;
    }

    .newsletter .btn {
        margin: 15px 0 0 0;
        height: 41px;
        line-height: 33px;
    }

    .home .benefits {
        padding: 50px 0 15px;
        overflow: hidden;
        position: relative;
    }

    .home .benefits .benefit {
        position: relative;
        margin: 0 0 35px 0;
    }

    .home .benefits .benefit h5 {
        padding: 0;
        height: inherit
    }

    .home .benefits .benefit .benefit-img {
        height: 60px;
        margin: 0 0 10px 0;
    }

    .home .benefits .benefit .benefit-img img {
        height: 60px;
        margin: 0 auto;
        display: block;
        max-width: 100px;
    }

    .similar-products,
    .product-recently,
    .new-offers {
        padding-left: 0;
        padding-right: 0;
        padding-top: 50px;
        padding-bottom: 5px;
    }

    .new-offers.products-list .product-item .product-name {
        min-height: 67px;
    }

    .offers .main-offer-text h3 {
        font-size: 14px;
        letter-spacing: 3px;
    }

    .offers .main-offer-text {
        bottom: 11%;
        padding-top: 0;
        padding-bottom: 0;
    }

    .offers .main-offer:hover .btn,
    .offers .small-offer:hover .btn {
        opacity: 0;
    }

    .offers .small-offer {
        margin: 0 0 30px 0;
    }

    .home .blog-list .blog-item,
    .blog-list .blog-item,
    .events .event,
    .assortment .assortment-item {
        margin: 0 0 30px 0;
    }

    .events .event-block:hover .event-info .btn {
        margin-bottom: 0;
    }

    .events .event-block .event-info .btn {
        padding: 0;
        background-color: transparent;
        border: 0;
        color: #E30615;
        line-height: 1.5;
        text-decoration: underline;
        max-height: 20px;
    }

    .events .event-block .event-info {
        padding: 25px 20px 0;
    }

    .events .event .event-info .event-meta .slots {
        margin-left: 0;
        display: block;
    }

    .events .event:hover .event-info .btn {
        max-height: 36px;
        line-height: 34px;
        margin-bottom: 20px;
    }

    .events .event .event-info {
        padding: 25px 20px 0;
    }

    .events .view-all {
        margin: 15px auto 0;
    }

    .blog-list .blog-item h3 a {
        font-size: 14px;
        line-height: 18px;
    }

    .blog-list .view-all {
        margin: 0 auto 0;
    }

    .products-list .product-item {
        margin-bottom: 50px;
    }

    .products-list .product-item .product-action {
        opacity: 1;
    }

    .products-list .product-item:hover .product-info {
        margin: initial;
        transform: initial;
    }

    .products-list .product-item:hover .product-action {
        opacity: 1;
    }

    .products-list .product-item:hover .add-fav {
        opacity: 0;
    }

    .add-review .review-info {
        padding: 30px 15px;
    }

    .add-review .product-rate > ul {
        display: block;
        margin-left: 0;
    }

    .add-review .product-rate > .product-stars {
        display: block;
        float: left;
        margin: 15px 0 30px 0;
        position: relative;
    }

    .add-review .form-hint {
        padding-right: 20px;
    }

    .add-review label {
        font-size: 14px;
    }

    .add-review .review-img {
        margin: 30px auto 30px;
        display: block;
        padding-left: 0;
    }

    .products-list.product-recently .product-item .product-prices .price,
    .products-list.product-recently .product-item .product-prices .old-price {
        font-size: 16px;
    }

    .products-list.product-recently .product-item .product-name {
        font-size: 16px;
        line-height: 22px;
    }

    .products-list .product-item:hover .product-img:after {
        margin-left: -70px;
    }

    .special-offers {
        padding-bottom: 0;
    }

    .assortment-img img {
        width: 100%;
    }

    .assortment .assortment-item {
        min-height: inherit;
    }

    .assortment .assortment-item .assortment-info {
        padding: 20px 30px 30px;
    }

    .assortment .assortment-item .btn {
        position: inherit;
        bottom: inherit;
        min-width: inherit;
        left: inherit;
        margin-left: inherit;
    }

    .assortment .assortment-item h3 {
        font-size: 14px;
        line-height: 18px;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .assortment .assortment-item .btn {
        padding: 0 15px;
    }

    .products-list .product-item .product-action .btn {
        padding: 5px 5px;
        height: inherit;
        line-height: 18px;
        letter-spacing: 0;
        font-size: 10px;
    }

    .login {
        background: none;
    }

    .blog-list .hero-area {
        padding: 50px 0;
    }

    .blog-list .hero-area h1 {
        font-size: 30px;
        line-height: 40px;
        margin: 0 0 0 0;
    }

    .blog-list .blog-item {
        margin-bottom: 30px;
    }

    .blog-list .blog-filter {
        margin-left: -5px;
        margin-bottom: 20px;
    }

    .blog-list .blog-filter li:first-of-type {
        margin-left: 5px;
    }

    .blog-list .blog-filter li {
        margin: 0 0 0 5px;
    }

    .blog-list .blog-filter li a {
        padding: 3px 10px;
        margin: 0 0 5px 0;
    }

    .blog-list .blog-item h3 {
        margin: 20px 15px 10px 15px;
    }

    .sort-result .form-control {
        margin: 0 0 10px 0;
    }

    .countries .country {
        margin: 0 0 10px 0;
        padding: 5px 10px;
    }

    .countries .view-all {
        float: none;
        padding: 5px 0;
        text-align: center;
    }

    .countries {
        margin: 0 0 20px 0;
        border-bottom: 0;
    }

    .maturity {
        padding: 20px 0;
        border-bottom: 1px solid #e3e2e2;
    }

    .types-cups {
        border-bottom: 1px solid #e3e2e2;
        padding: 0 0 20px 0;
    }

    .product .temperature {
        padding: 20px 0;
        border-bottom: 1px solid #e3e2e2;
    }

    .product .suitable-foods {
        padding: 20px 0;
        border-bottom: 1px solid #e3e2e2;
    }

    .product .suitable-foods ul li,
    .product .product-aromas ul li {
        margin-bottom: 15px;
    }

    .product .product-sort {
        padding: 20px 0;
        margin: 0;
    }

    .product .product-aromas {
        padding: 20px 0 0 0;
    }

    .product .product-sort p {
        margin: 0;
    }

    .product .product-profile {
        margin: 30px 0 30px 0;
    }

    .product .product-desc {
        margin: 20px 0 20px;
    }

    .product .product-features.gray {
        background-color: #FBFAF9;
        padding-bottom: 10px;
    }

    .product .product-features {
        padding: 20px 0;
    }

    .product .product-info {
        padding: 30px 0 0;
    }

    .product .buy {
        width: 100%;
    }

    .product .buy:hover {
        background-color: #e30613;
        border-color: #e30613;
        color: #fff;
    }

    .product .product-quantity {
        margin: 0 0 10px 0;
    }

    .product .product-variants .product-variant {
        margin-bottom: 10px;
    }

    .variant-error {
        margin: 2px 0 0 0;
    }

    .btn {
        padding: 0 15px;
    }

    .product .product-img {
        margin: 0 0 0 0;
    }

    .product .assortment {
        padding-top: 50px;
        padding-bottom: 0;
    }

    .product .assortment .heading {
        margin-bottom: 30px;
    }

    .comments .heading {
        margin-bottom: 30px;
    }

    .comments .rate-list {
        margin: 0 0 0 0;
    }

    .comments .rating .lab {
        margin: 0 0 5px;
    }

    .comments .rating {
        text-align: center;
    }

    .comments .rate-list .btn {
        margin: 15px auto 29px auto;
        display: table;
        width: 100%;
    }

    .profile:after {
        display: none;
    }

    .user-menu {
        margin: 10px 0 0 0;
    }

    .profile .profile-content {
        margin: 30px 0 30px 0;
        border-top: 1px solid #DCDAD9;
        border-right: 1px solid #DCDAD9;
        padding: 25px 20px 45px 20px;
        height: auto;
        border-bottom: 1px solid #dcdad9;
    }

    .alert-success {
        margin-bottom: 0;
        margin-top: 30px;
    }

    .profile .heading {
        margin: 0 0 20px 0;
    }

    .profile .form-group {
        margin: 0 0 20px 0;
    }

    .products .hero-area {
        padding: 30px 0;
    }

    .products .hero-area h1 {
        font-size: 34px;
        line-height: 40px;
    }

    .cstm-pagination ul li .page-numbers {
        line-height: 26px;
        font-size: 12px;
        width: 30px;
        height: 30px;
        border-width: 1px;
        margin: 0 3px;
    }

    .gift-action {
        margin: 20px 0 0 0;
    }

    .order-payment .payment-method label .payment-name {
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 5px 0;
    }

    .order-payment .payment-method label {
        padding: 15px 65px 15px 45px;
    }

    .container .message-div {
        font-size: 14px;
    }

    .promo-code {
        margin: 0 0 15px 0;
        padding:15px;
    }

    .thankyou h1 {
        font-size: 18px;
        line-height: 1.5;
        margin: 0 0 30px 0;
    }

    .checkout {
        padding: 0 0 50px 0;
    }

    .checkout .payment-method {
        margin: 0 0 10px 0;
    }

    .order-payment {
        padding: 15px;
    }

    .mb-cart-heading {
        margin: 15px 0 5px 0;
        color: #afafaf;
    }

    .cart {
        padding: 15px 0 50px;
    }

    .cart h1 {
        font-size: 26px;
        line-height: 32px;
    }

    .cart-list {
        padding: 10px 15px;
    }

    .cart-headings {
        display: none;
    }

    .cart-list .cart-row .package-discounts .badge {
        font-size: 10px;
        white-space: inherit;
    }

    .cart-delivery-hint {
        padding: 10px 15px;
        margin: 15px 0 10px 0;
    }

    footer {
        background-image: none;
        padding: 50px 0 30px;
    }

    footer .footer-menu {
        margin: 0 0 30px 0;
    }

    footer h3 {
        margin: 0 0 10px 0;
    }

    footer ul li {
        margin-bottom: 3px;
    }

    footer .footer-contacts.visit-us li {
        padding-left: 28px;
    }

    footer .footer-contacts li {
        color: #b3b3b3;
        margin-bottom: 20px;
        padding-left: 0;
    }

    footer .footer-logo {
        margin: 10px auto 0;
    }

    .events .event-block {
        margin: 0 0 15px 0;
    }

    .events .event-block .event-info h3 {
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 5px 0;
    }

    .events .event-block .event-info .event-meta {
        font-size: 14px;
    }

    .events .event-block .event-info .event-meta .slots {
        display: block;
        margin: 0;
    }

    /*header.fixed ~ .page {*/
    /*    padding-top: 58px !important;*/
    /*}*/

    .products-list .product-item .product-desc {
        font-size: 12px;
        height: 63px;
        overflow: hidden;
        position: relative;
    }

    .products-list .product-item .product-name {
        font-size: 14px;
        line-height: 18px;
        height: 71px;
    }

    .cart-list .product-desc {
        margin: 0 0 10px 0;
    }

    .total h3 {
        font-size: 16px;
        line-height: 22px;
    }

    .empty-category img {
        height: 150px;
    }

    .contacts .contact-form {
        padding: 20px 20px 80px 20px;
    }

    .contacts:after {
        display: none;
    }

    .contacts .short-map iframe {
        height: 350px;
    }

    .contacts .contact-info ul li:last-of-type {
        border: 0;
    }

    .contacts .contact-form {
        border: 0;
    }

    .contacts .contact-info ul li {
        padding: 25px 10px 25px 20px;
    }

    .contacts .contact-info {
        padding: 15px 0 0 0;
    }

    .promo-code .btn {
        padding: 0 10px;
    }

    .total .product-row .product-name {
        font-size: 12px;
        line-height: 18px;
        margin: 0 0 10px 0;
    }

    .total .product-row .product-old-price {
        text-align: right;
    }

    .order-info .select2{
        margin-bottom:15px;
    }

    .total-action{
        padding:15px;
    }
    .total-action .checkbox{
       margin:0 0 15px 0;
    }

    .assortment .assortment-item h3,
    .blog-list .blog-item h3 {
        min-height: inherit;
    }

    .product .product-prices {
        text-align: center;
    }

    .products-list .product-item .product-action .btn-secondary-outline {
        display: none;
    }

    .products-list .product-item .product-action .btn-primary {
        margin: 0;
    }

    .profile .order-details .val {
        margin: 0 0 10px 0;
        line-height: 18px;
    }

    .profile .product-price {
        text-align: left;
        margin: 5px 0 0 0;
        font-weight: bold;
        font-size: 14px;
        color: #000;
    }

    .profile-orders .order-num,
    .profile-content .order-num {
        padding: 0 0 20px 0;
        font-size: 14px;
        line-height: 20px;
    }

    .profile-content .order-num {
        padding: 10px 0 20px 0;
    }

    .profile .order-products {
        border-top: 0;
    }

    .profile .order-products .product-row:first-of-type {
        padding-top: 0;
    }

    .profile .order-details {
        margin: 0 0 20px 0;
    }

    .profile .order-products .product-row .product-name {
        margin: 0;
    }

    .profile .profile-content .order-row .order-num span {
        display: block;
    }

    .profile .profile-content .order-row .order-num a.pay-again {
        min-width: 100%;
        justify-content: center;
    }

    .profile .profile-content .order-row .order-num .pay-now {
        width: 100%;
    }

    .profile .profile-content .order-row .order-num span.btn-text {
        max-width: 100% !important;
    }

    .profile .profile-content .order-row .buttons-block {
        width: 100%;
        justify-content: space-between;
        margin: 0;
    }

    .profile .profile-content .order-row .order-num div a.btn {
        max-width: 100%;
        width: 100%;
    }

    .profile .profile-content .order-row .cancel-button,
    .profile .profile-content .order-row .btn-order {
        width: 48%;
    }

    .profile .profile-content .order-row .order-num a.replay,
    .profile .profile-content .order-row .order-num a.cancel {
        background: white;
        border: none;
        color: #303539;
    }

    .profile .profile-content .order-row .order-num a.replay:before {
        background: url("../../images/icon_3-gray.svg") center no-repeat;
    }

    /*TODO*/
    .profile-address-list .btn {
        margin: 20px 0 0 0;
    }

    .sidebar .active-filters h4 {
        margin: 0 0 10px 0;
    }

    .sidebar .active-filters {
        padding: 0 0 20px;
    }

    .sidebar .active-filters ul li {
        display: inline-block;
        margin: 0 10px 3px 0;
    }

    .sidebar .widget {
        padding: 10px 0;
    }

    #wo-breadcrumbs {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        overflow-x: scroll;
        padding-bottom: 7px;
        margin-bottom: -7px;
    }

    .custom-select {
        font-size: 14px;
    }

    .sidebar.active .widgets {
        display: none;
    }

    .gift-card,
    .gift,
    .order-info,
    .date-info,
    .order-delivery {
        padding: 15px;
        margin: 0 0 15px 0;
    }

    .order-info label {
        font-size: 12px;
    }

    .magnum-offer {
        padding-bottom: 50px;
    }

    .magnum-offer .magnum-offer-cont {
        border-bottom: 1px solid #D9D9D9;
        padding: 0 15px 10px 15px;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block {
        padding: 25px 0;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .person-type {
        text-align: center;
        font-size: 17px;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .person-type img {
        max-height: 50px;
        margin-bottom: 5px;
        margin-right: 10px;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block span {
        margin: 0 auto;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift span {
        max-width: 100px;
        text-align: center;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block h5 {
        font-family: 'FedraSansPro', sans-serif;
        text-transform: uppercase;
        font-size: 15px;
        text-align: center;
        color: #6A6665;
        margin-top: 18px;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift {
        min-height: 131px;
    }

    .magnum-offer .magnum-offer-cont .magnum-offer-block .bottle-gift img {
        display: block;
        margin: 3px auto 3px auto;
    }

    .order-delivery h4,
    .order-info h3,
    .order-delivery h3,
    .gift-card h3,
    .gift h3 {
        font-size: 14px;
        line-height: 18px;
    }
    .gift h3 {
       padding: 0;
    }
    .order-info h3,
    .order-delivery h3 {
        padding: 0 0 10px 0;
        margin: 0 0 15px 0;
    }

    .order-delivery .delivery-method label {
        padding: 10px 10px 10px 45px;
    }

    .order-delivery .delivery-method input[type=radio] {
        margin-top: -8px;
    }

    .order-delivery .delivery-method {
        margin: 0 0 10px 0;
    }

    .total h3 {
        margin-bottom: 0;
        border: 0;
    }

    .sum ul li span {
        font-weight: bold;
    }

    .gift-package label {
        margin-left: 5px;
        display: inline;
    }

    .intro h2 {
        font-size: 32px;
        line-height: 38px;
    }

    .carousel-item {
        height: 450px;
    }

    .intro .carousel-caption {
        bottom: 10%;
    }

    .order-auth-login {
        padding: 0 20px 30px;
    }

    .order-auth {
        background: none;
    }

    .space.order-auth {
        padding: 25px 0;
    }

    .order-auth .heading {
        margin: 0 0 15px 0;
    }

    .order-auth .or {
        text-align: center;
        margin: 11px 0;
    }

    .order-auth h1 {
        font-size: 18px;
    }

    .age-gate .btn {
        margin: 0 5px 5px;
        min-width: 100%;
    }

    #flash-messages-wrapper {
        padding-bottom: 20px;
    }

    #flash-messages-wrapper:empty {
        padding-bottom: 0;
    }

    .review-modal .review-info select.form-control {
        margin-left: 5px;
    }



    .intro .btn {
        letter-spacing: 2px;
        padding: 0 40px;
    }

    .profile-address .profile-content .magnum-offer .magnum-offer-cont {
        min-width: auto;
        padding: 0;
    }

    .profile-address .profile-content .magnum-offer .col-12 {
        padding: 0;
    }

    .subscribe-land {
        min-height: 990px;
        background-size: 600px;
    }

    .subscribe-land .heading {
        font-size: 25px;
        line-height: 39px;
        margin-bottom: 20px;
    }

    .subscribe-steps .subscribe-step {
        margin-bottom: 25px;
    }

    .subscribe-steps .subscribe-step span {
        width: 40px;
        height: 40px;
        line-height: 38px;
        font-size: 18px;
    }

    .subscribe-land p,
    .subscribe-steps .subscribe-step .step-text {
        font-size: 15px;
    }

    .subscribe-land .left-shape {
        max-width: 150px;
    }

    .subscribe-land .right-shape {
        max-width: 150px;
        bottom: 0;
    }

    .subscribe-land .bottom-desc {

    }
    .upsell .upsell-product .product-action {
        text-align: right;
    }

    .instant-open .algolia-search {
        z-index: 999;
        margin-top: 0;
        padding: 10px 15px 120px 15px;
        overflow-y: auto;
        height: calc(100vh - 30px);
    }



    .algolia-search .ais-SearchBox {
        margin: 0 0 15px 0;
        max-width: 100%;
        width: 100%;
    }

    .algolia-search .ais-SearchBox-input::placeholder {
        font-size: 14px;
    }

    .algolia-search #filters {
       display: none;
        max-height: calc(100vh - 460px);
        margin: 0;
    }

    #hits .ais-Hits-item h3 {
        font-size: 14px;
        line-height: 18px;
        min-height: 71px;
    }

    #hits .ais-Hits-item {
        width: calc(50% - 1rem);
    }

    .instant-open .algolia-search .toggle-filter {
        margin-bottom: 25px;
    }

    .instant-open .user-widgets .widget-item.search-widget {
        position: relative;
    }

    .instant-open header .user-widgets {
        padding-right: 0;
    }
    .user-widgets .widget-item {
        padding: 0 4px;
    }

    .user-widgets .wisp_ready svg {
        width: 26px !important;
        height: 26px !important;
    }

    .user-widgets .widget-item .icn {
        height: 20px;
        margin-top: 0;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .loyalty-card-block .card-info {
        height: 200px;
        width: 356px;
        padding: 20px 15px 20px 110px;
    }

    .loyalty-card-block .card-info .title {
        font-size: 20px;
    }

    .loyalty-card-block .user-info-block img {
        width: 24px;
    }

    .loyalty-card-block .user-info-block h5 {
        font-size: 14px;
        line-height: 22px;
    }

    .loyalty-card-block .barcode-info {
        width: 150px;
        padding: 20px 18px;
    }

    .loyalty-card-block .barcode-info .card-number {
        font-size: 18px;
    }

    .loyalty-card-block .barcode-block img {
        width: 65px;
    }

    .loyalty-card-block .barcode-info a {
        font-size: 15px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 574px) {

    .loyalty-card-block {
        width: 258px;
        position: relative;
    }

    .loyalty-card-block .card-info {
        min-height: 143px;
        height: 100%;
        width: 258px;
        padding: 16px 15px 16px 60px;
        border-radius: 16px 16px 0 0;
        position: relative;
        z-index: 1;
    }

    .loyalty-card-block .card-info:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 10px;
        left: 0;
        bottom: 0;
        background: #fff;
        margin-bottom: -6px;
    }

    .loyalty-card-block .card-info .title {
        font-size: 17px;
    }

    .loyalty-card-block .user-info-block img {
        width: 20px;
    }

    .loyalty-card-block .user-info-block .person-type {
        font-size: 12px;
        margin-top: -6px;
    }

    .loyalty-card-block .user-info-block h5 {
        font-size: 13px;
        line-height: 18px;
    }

    .loyalty-card-block .barcode-info {
        width: 100%;
        height: 100%;
        margin-top: 0;
        padding: 16px 15px 20px 15px;
        border-radius: 0 0 16px 16px;
        position: relative;
        z-index: 5;
    }

    .loyalty-card-block .barcode-info .card-number {
        font-size: 18px;
    }

    .loyalty-card-block .barcode-block img {
        width: 80px;
        margin: 14px auto;
    }

    .loyalty-card-block .barcode-info a {
        font-size: 16px;
    }

    .sommelier-recommendations .sommelier-method .sommelier-img img {
        width: 120px;
    }

    .sommelier-recommendations .sommelier-method label {
        margin-bottom: 15px;
    }
}

@media screen and (orientation: landscape)
and (min-device-width: 0)
and (max-device-width: 767px) {

    .order-auth-login {
        padding: 60px 10px;
    }

    .categories {
        padding-bottom: 0;
    }

    .delivery-hint span {
        display: inline-block;
    }

    .top .top-item.social {
        float: none;
        margin-right: 35px;
    }

    .top .top-item:after {
        content: "";
        height: 15px;
        width: 1px;
        background-color: #753e86;
        display: block;
        position: absolute;
        top: 3px;
        right: -16px;
    }

    .top .top-item.phone {
        display: inline-block;
    }

    .delivery-hint .sep {
        display: inline-block;
    }

    .owl-carousel .owl-stage {
        padding-left: 0 !important;
    }

    .assortment-info .btn {
        height: inherit;
        line-height: 18px;
        padding: 10px 20px;
    }

    .comments .rate-list .btn {
        margin: 5px auto 29px auto;
    }

    .blog-list .blog-item h3 a {
        font-size: 14px;
        line-height: 20px;
    }

    .blog-list .blog-item h3 {
        margin: 20px 0 5px 0;
    }

    .team .person img {
        margin: 0 auto;
        display: block;
    }

    .products-list .product-item .product-name {
        min-height: 67px;
    }

    .assortment .assortment-item .btn {
        padding: 7px 15px;
    }

    .product .product-prices {
        text-align: left;
    }
}

@media screen and (orientation: landscape)
and (min-device-width: 768px)
and (max-device-width: 1024px) {

    .space {
        padding: 65px 0;
    }

    .intro h2 {
        font-size: 34px;
        line-height: 44px;
    }

    .categories .products-list .product-item {
        margin-bottom: 70px;
    }

    .products-list .product-item {
        margin-bottom: 0;
    }

    .products-list .product-item .product-action {
        opacity: 1;
    }

    .products-list .product-item:hover .product-info {
        margin: 0 0 0 0;
        transform: translate(0, 0);
    }

    .assortment .assortment-item .assortment-info {
        padding: 20px 15px 30px;
    }

    .assortment .assortment-item h3 {
        font-size: 18px;
        line-height: 24px;
    }

    .events .event-space {
        margin-bottom: 22px;
    }

    .blog-list .blog-item h3 {
        margin: 20px 0 10px;
    }

    .blog-list .blog-item h3 a {
        font-size: 18px;
        line-height: 24px;
    }

    .events .event-block .event-info h3 {
        font-size: 18px;
        line-height: 24px;
    }

    .newsletter .hint {
        padding: 0 100px;
    }

    .home .blog-list {
        padding-bottom: 0;
    }

    .home .benefits .benefit h5 {
        font-size: 14px;
        line-height: 20px;
    }

}

/*slider-module*/

@media (max-width: 880px) {

    .slider-module .owl-carousel .item {
        height: 400px;
    }

    .slider-module .owl-carousel .carousel-block {
        position: static;
        padding-top: 55px;
        padding-bottom: 105px;
    }
}

@media (max-width: 767px) {
    .slider-module .owl-carousel .carousel-block h2 {
        font-size: 34px;
        line-height: 40px;
    }

    .slider-module .owl-carousel .carousel-block h1 {
        font-size: 14px;
        line-height: 23px;
    }
}

@media (max-width: 575px) {
    .slider-module .owl-carousel .carousel-block h1,
    .slider-module .owl-carousel .carousel-block h2 {
        padding: 0 10px;
    }

    .profile .profile-content .order-row .order-num span {
        display: block;
        width: 100%;
    }

    .profile .profile-content .order-row .order-num a span {
        display: block;
        width: 100%;
        max-width: 149px !important;
        padding: 6px 0 6px 25px !important;
    }

    .profile .profile-content .order-row .order-num div a.btn {
        font-size: 9px;
        padding: 0 6px;
        line-height: 15px;
        min-height: 43px;
    }

    .profile-content .order-row {
        padding: 11px 10px;
    }

    .profile .profile-content {
        padding: 25px 15px 35px 15px;
    }

    .profile .order-row .view-order {
        margin: 11px 0 0 auto;
    }

    .profile .profile-content .order-row .order-num a.replay:before {
        margin-left: 0;
        margin-top: -2px;
    }

    .profile .profile-content .order-row .order-num a.cancel:before {
        margin-left: 0;
    }

    .profile-content .order-num .cancel-button {
        width: 104px !important;
    }

    .profile .profile-content .order-row .order-num div a.pay-again {
        text-align: center;
        padding-left: 0 !important;
    }

    .profile .profile-content .order-row .order-num a.pay-again span:before {
        left: auto;
    }

}

@media (max-width: 425px) {
    .slider-module .owl-carousel .owl-nav button.owl-next,
    .slider-module .owl-carousel .owl-nav button.owl-prev {
        margin: -43px 0 0 0;
        background: transparent;
    }

    .slider-module .owl-carousel .carousel-block h2 {
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 2px;
    }

    .slider-module .owl-carousel .carousel-block p {
        width: 100%;
        text-align: center;
        margin: 0 0 22px 0;
        padding: 0 20px;
    }
}

@media (max-width: 425px) and (min-width: 370px) {
    .slider-module .owl-carousel .owl-nav button.owl-next,
    .slider-module .owl-carousel .owl-nav button.owl-prev {
        margin: -64px 0 0 0;
    }
}

@media (max-width: 370px) {
    .slider-module .owl-carousel .carousel-block h2 {
        padding: 0 25px;
    }
}

/*two-banners-module*/

@media (max-width: 1150px) {
    .two-banners-module .two-banners-module-container {
        padding: 0 70px;
    }
}

@media (max-width: 1100px) {
    .two-banners-module .two-banners-module-container {
        padding: 0;
    }
}

@media (max-width: 992px) {
    .two-banners-module .module-banner .module-banner-choice h2 {
        line-height: 34px;
        margin-bottom: 4px;
    }

    .two-banners-module .module-banner .module-banner-choice h3 {
        font-size: 35px;
        line-height: 40px;
    }
}

@media (max-width: 860px) {
    .two-banners-module .module-banner img {
        min-width: 100%;
    }
}

@media (max-width: 767px) {

    .two-banners-module {
        padding: 50px 0 10px 0;
    }

    .two-banners-module .module-banner {
        text-align: center;
        margin-bottom: 20px;
    }

    .two-banners-module img.right-decoration {
        margin-bottom: -135px;
        width: 135px;
    }

    .two-banners-module .module-banner .module-banner-choice.left-banner {
        width: 100%;
        left: 0;
        margin: 0;
        text-align: left;
        padding-left: 28px;
    }

    .two-banners-module .module-banner .module-banner-choice.left-banner h3 {
        text-align: left;
    }

    .two-banners-module .module-banner .module-banner-choice.left-banner .red-eclipse {
        left: 0;
        margin-left: 165px;
    }
}

@media (max-width: 600px) {
    .two-banners-module img.right-decoration {
        margin-bottom: -88px;
        width: 135px;
    }
}

@media (max-width: 575px) {
    .two-banners-module {
        padding: 40px 0 10px 0;
    }
}

@media (max-width: 425px) {
    .two-banners-module .module-banner .module-banner-choice.left-banner {
        bottom: 15%;
    }

    a.btn.buy {
        padding: 0 35px
    }

    .two-banners-module .module-banner .module-banner-choice.right-banner a.buy {
        margin-left: 25px;
    }

    .two-banners-module img.right-decoration {
        width: 98px;
    }

    .two-banners-module img.left-decoration {
        width: 70px;
    }

    .two-banners-module .module-banner .module-banner-choice.right-banner {
        margin-right: 25px;
    }
}

@media (max-width: 1333px) {
    .product-slide-module .owl-carousel .owl-nav button.owl-next {
        margin-right: -9px;
    }

    .product-slide-module .owl-carousel .owl-nav button.owl-prev {
        margin-left: -34px;
    }

}

@media (max-width: 1315px) {
    .product-slide-module .owl-carousel {
        padding: 0 35px;
    }

    .product-slide-module .owl-carousel .owl-nav button.owl-next {
        margin-right: 0;
    }

    .product-slide-module .star-decoration {
        top: 24%;
    }

    .product-slide-module .owl-carousel .owl-nav button.owl-prev {
        margin-left: 0;
    }

    .product-slide-module .owl-carousel .owl-nav button.owl-prev span {
        left: 0;
    }

    .product-slide-module .owl-carousel .owl-nav button.owl-next span {
        left: 0;
    }
}

@media (max-width: 1150px) {
    .product-slide-module .see-more {
        margin-top: 10px;
    }
}

@media (max-width: 1140px) {
    .product-slide-module .gift-box-sec {
        left: 17%;
    }
}

@media (max-width: 1024px) {
    .product-slide-module .see-more {
        margin-top: -41px;
    }
}

@media (max-width: 992px) and (min-width: 575px) {
    .product-slide-module .left-mask-2 {
        top: 23%;
    }
}

@media (max-width: 992px) {
    .product-slide-module h2 {
        padding: 0 20px;
        margin: 64px 0 0 0;
        width: 100%;
        font-size: 30px;
        line-height: 50px;
    }

    .holiday-offers .product-article .product-info .product-action {
        height: 100%;
    }

    .holiday-offers .product-article .product-info .product-action {
        opacity: 1;
        background: white;
    }

    .holiday-offers .product-article .product-info .product-action a.view {
        display: none;
    }

    .product-slide-module .see-more {
        margin-top: 10px;
    }

    .product-slide-module.holiday-offers .product-article .product-info .product-action {
        padding: 0 15px 20px 15px;
        height: 100%;
    }
}

@media (max-width: 820px) {
    .product-slide-module .gift-box-sec {
        left: 10%;
    }
}

@media (max-width: 767px) {
    .product-slide-module h2 {
        font-size: 26px;
        line-height: 43px;
    }

    .product-slide-module.holiday-offers .product-article .product-info .product-action {
        height: 100%;
    }

    .product-slide-module.holiday-offers .product-article .product-info .product-action a {
        font-size: 12px;
    }

    .holiday-offers .product-article {
        height: 100%;
    }

    .holiday-offers .product-article .product-info:hover {
        transform: none;
    }

    .product-slide-module .wine-img img {
        width: 200px;
    }

    .product-slide-module .gift-box {
        width: 111px;
        margin-bottom: -104px;
    }

    .product-slide-module .owl-carousel {
        padding: 0;
    }

    .product-slide-module .star-decoration {
        top: 22%;
    }
}

@media (max-width: 666px) {
    .product-slide-module .gift-box-sec {
        left: 5%;
        width: 123px;
    }
}

@media (max-width: 640px) {

    .product-slide-module .products-list .product-item {
        height: 550px;
    }

    .product-slide-module .products-list .product-item .product-action {
        opacity: 1;
        display: block;
    }
}

@media (max-width: 575px) {
    .product-slide-module h2 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 23px;
        line-height: 35px;
        padding: 0 5px;
    }

    .product-slide-module .wine-img {
        text-align: center;
    }

    .product-slide-module.holiday-offers .product-article .product-info .product-name {
        padding: 13px 5px 23px 5px;
    }
}


@media (max-width: 500px) {
    .product-slide-module .star-decoration {
        width: 94px;
        top: 29%;
    }

    .product-slide-module .decoration {
        width: 96px;
        margin-left: -48px;
        margin-top: -20px;
    }
}


@media (max-width: 425px) {
    .product-slide-module.holiday-offers .product-article .product-info .product-action {
        padding: 0 10px;
    }

    .product-slide-module h2 {
        font-size: 20px;
        line-height: 33px;
    }

    .product-slide-module h3 {
        margin-top: 40px;
    }

    .product-slide-module .star-decoration {
        top: 26%;
    }

    .product-slide-module .owl-carousel {
        padding: 0;
    }

    .holiday-offers .product-article .product-info .product-name {
        height: 76px;
    }

    .product-slide-module .gift-box {
        width: 82px;
        margin-bottom: -80px;
    }

    .product-slide-module .gift-box-sec {
        width: 103px;
        left: 0;
    }

    .product-slide-module.holiday-offers .product-article .product-info .product-name:after {
        margin-bottom: 12px;
    }

    .product-slide-module .product-article .product-info .product-name h5 {
        padding: 3px 8px 7px 8px;
        height: 67px;
    }

    .product-slide-module.holiday-offers .product-article .product-info .product-prices {
        padding: 0 0 15px 0;
    }


    .product-slide-module.holiday-offers .product-article {
        margin-bottom: 0;
    }

    .product-slide-module .left-mask-2 {
        width: 160px;
        margin-left: -120px;
    }
}

@media (max-width: 361px) {
    .product-slide-module .star-decoration {
        width: 83px;
    }
}

@media (max-width: 340px) {
    .product-slide-module .star-decoration {
        top: 31%;
    }
}

@media (max-width: 321px) {
    .product-slide-module .star-decoration {
        top: 32%;
    }
}

/*holiday-offers*/

@media (max-width: 1200px) {
    .holiday-offers .category-choice {
        padding: 0 40px;
    }

    .holiday-offers .product-article .product-info.tequila .product-name {
        padding: 13px 10px 20px 10px;
    }
}

@media (max-width: 1187px) {
    .holiday-offers .product-article .product-info .product-prices {
        padding: 12px 0 15px 0;
    }

    .holiday-offers .product-article .product-info .product-desk {
        min-height: 75px;
    }

    .holiday-offers .product-article .product-info .product-name {
        padding: 13px 5px 20px 5px;
    }
}

@media (max-width: 992px) {
    .holiday-offers .category-choice .col-md-4:nth-child(1) button,
    .holiday-offers .category-choice .col-md-4:nth-child(2) button,
    .holiday-offers .category-choice .col-md-4:nth-child(3) button {
        margin-bottom: 10px;
    }

    .holiday-offers .product-article .product-info .product-action a {
        font-size: 12px;
    }

    .holiday-offers .product-article {
        margin-bottom: 30px;
    }

    .holiday-offers .product-article.see-more img.bgr-effect,
    .holiday-offers .product-article.see-more {
        height: 92%;
    }

    .holiday-offers.food-and-wine .product-article.see-more img.bgr-effect,
    .holiday-offers.food-and-wine .product-article.see-more {
        height: 93%;
    }

    .holiday-offers.slide-offer .owl-theme .owl-nav.disabled + .owl-dots {
        margin-top: -19px;
    }
}

@media (max-width: 767px) {
    .holiday-offers {
        padding-top: 25px;
    }

    .holiday-offers .product-article.see-more img.bgr-effect {
        position: static;
        width: 100%;
    }

    .holiday-offers h3 {
        font-size: 22px;
        line-height: 29px;
    }

    .holiday-offers .product-article.see-more img.bgr-effect {
        height: 100%;
    }

    .holiday-offers .category-choice button {
        margin-bottom: 10px;
    }

    .holiday-offers.slide-offer .product-article .product-info:hover {
        transform: none;
    }

    .holiday-offers.slide-offer .gift-image {
        width: 475px;
        margin-top: -253px;
    }

    .product-slide-module .product-info {
        transform: none;
    }
}

@media (max-width: 575px) {
    .holiday-offers .product-article .product-info .product-name:after {
        margin-bottom: 7px;
    }

    .holiday-offers .product-article.see-more .more-info {
        width: 100%;
        text-align: center;
    }

    .holiday-offers .product-article .product-info .product-desk {
        min-height: 100%;
        padding-top: 7px;
    }

    .holiday-offers .product-article .product-info .product-prices {
        padding: 10px 0 15px 0;
    }

    .holiday-offers .product-article .product-info .product-name {
        padding: 13px 5px 25px 5px;
        height: auto;
    }

    .holiday-offers.slide-offer .product-article .product-info .product-name {
        height: 80px;
    }

    .holiday-offers.slide-offer .product-article .product-info .product-desk {
        min-height: 43px;
    }

    .holiday-offers .product-article.see-more .more-info {
        font-size: 21px;
        line-height: 33px;
    }
}

@media (max-width: 540px) {
    .holiday-offers.slide-offer .gift-image {
        display: none;
    }
}

@media (max-width: 425px) {
    .holiday-offers {
        padding-top: 15px;
    }

    .holiday-offers.food-and-wine .product-article.see-more img.bgr-effect,
    .holiday-offers.food-and-wine .product-article.see-more {
        max-height: 439px;
    }

    .holiday-offers h3 {
        font-size: 20px;
        line-height: 30px;
        padding: 0 38px;
    }

    .holiday-offers .product-article {
        margin-bottom: 25px;
    }

    .holiday-offers .category-choice {
        padding: 0;
    }
}


@media (max-width: 640px) {
    .product-slide-module {
        padding-top: 45px;
    }
}

@media (max-width: 414px) {
    .holiday-offers.food-and-wine .product-article.see-more img.bgr-effect,
    .holiday-offers.food-and-wine .product-article.see-more {
        max-height: 423px;
    }
}

@media (max-width: 375px) {
    .holiday-offers.food-and-wine .product-article.see-more img.bgr-effect,
    .holiday-offers.food-and-wine .product-article.see-more {
        max-height: 376px;
    }
}

@media (max-width: 360px) {
    .holiday-offers.food-and-wine .product-article.see-more img.bgr-effect,
    .holiday-offers.food-and-wine .product-article.see-more {
        max-height: 360px;
    }
}

@media (max-width: 350px) {
    .holiday-offers.food-and-wine .product-article.see-more img.bgr-effect,
    .holiday-offers.food-and-wine .product-article.see-more {
        max-height: 343px;
    }
}

@media (max-width: 320px) {
    .holiday-offers.food-and-wine .product-article.see-more img.bgr-effect,
    .holiday-offers.food-and-wine .product-article.see-more {
        max-height: 306px;
    }
}


/*newsletter.gift*/

@media (max-width: 992px) {
    .newsletter.gift h3 {
        padding: 0 189px;
    }

    .newsletter.gift p {
        padding: 0 75px;
    }
}

@media (max-width: 854px) {
    .newsletter.gift h3 {
        padding: 0 25px;
    }
}

@media (max-width: 767px) {

    .newsletter.gift {
        padding: 50px 0 50px 0;
    }

    .newsletter.gift h3 {
        padding: 0 20px;
    }

    .newsletter.gift p {
        padding: 0 20px;
    }

    .newsletter.gift form {
        padding: 0 25px;
    }

    .newsletter.gift form button {
        margin: 0;
    }

    .newsletter.gift .newsletter-form {
        padding: 26px 0 50px 0;
    }
}

@media (max-width: 575px) {

    .newsletter.gift {
        padding: 40px 0 50px 0;
    }

    .newsletter.gift h3 {
        font-size: 20px;
        line-height: 33px;
        margin-top: 15px;
    }

    .newsletter.gift .form-group.checkbox2 label {
        font-size: 12px;
        line-height: 18px;
    }

    .newsletter .form-control {
        font-size: 13px;
        height: 41px;
    }
}

@media (max-width: 425px) {
    .newsletter.gift h3 {
        padding: 0 11px;
    }
}


/*food-and-wine*/

@media (max-width: 767px) {
    .food-and-wine {
        padding-top: 45px
    }
}

@media (max-width: 575px) {
    .holiday-offers .star-decor {
        display: none;
    }
}

@media (max-width: 425px) {
    .food-and-wine {
        padding-top: 35px
    }

    .holiday-offers.food-and-wine .product-article .product-image a {
        padding: 13px 65px;
    }
}


/*decorated-footer-module*/

@media (max-width: 767px) {
    .decorated-footer-module .footer-decor-3 {
        width: 236px;
    }

    .decorated-footer-module .footer-decor-2 {
        right: 29%;
        width: 142px;
    }
}

@media (max-width: 575px) {

    .decorated-footer-module {
        padding: 15px 0 65px 0;
    }

    .decorated-footer-module .footer-decor-3 {
        width: 203px;
        margin-bottom: -65px;
    }

    .decorated-footer-module .footer-decor-2 {
        display: none;
    }

    .decorated-footer-module .wine-decor {
        width: 200px;
    }
}

@media (max-width: 425px) {
    .decorated-footer-module .footer-decor-3 {
        width: 185px;
        margin-bottom: -87px;
    }

    .decorated-footer-module .footer-decor-2 {
        width: 113px;
        margin-bottom: -9px;
    }

    .decorated-footer-module .wine-decor {
        width: 181px;
    }
}

@media (max-width: 390px) {

    .decorated-footer-module {
        padding: 0 0 65px 0;
    }

    .decorated-footer-module p {
        padding: 0 20px;
    }

    .decorated-footer-module .wine-decor {
        width: 161px;
        margin-bottom: 0;
        margin-left: -36px;
    }
}

@media (min-width: 992px) {
    .product-slide-module.holiday-offers .product-article {
        height: 484px;
    }

    .holiday-offers .product-article {
        height: 514px;
    }
}

@media (max-width: 1024px) {

    .intro .intro-items .our-logo img {
        max-width: 216px;
    }

    .intro .intro-items .our-logo img:last-child {
        max-height: 30px;
    }
}

@media (max-width: 991px) {

    .intro .intro-items .our-logo {
        justify-content: center;
    }
}

@media (max-width: 575px) {

    .intro .intro-items .our-logo {
        margin: 24px 0 28px 0;
    }

    .intro .intro-items .our-logo span {
        margin-top: 2px;
        font-size: 15px;
    }

    .intro .intro-items .our-logo img {
        max-width: 165px;
    }

    .intro .intro-items .our-logo img:last-child {
        max-height: 23px;
    }
}

@media (max-width: 320px) {
    .intro .intro-items .our-logo img {
        margin: 0 10px;
        max-width: 132px;
    }

    .intro .intro-items .our-logo img:last-child {
        max-height: 17px;
    }
}

@media (max-height: 640px) and (max-width: 991px) {
    header .menu {
        height: 100vh;
        padding-bottom: 55px;
    }
}

/*mystery-box-module*/


@media (max-width: 1200px) {
    .mystery-box-module h3 {
        margin-top: 5px;
    }

    .mystery-box-module .mystery-star {
        margin-top: -94px;
        top: 0;
    }
}

@media (max-width: 992px) {
    .mystery-box-module h3 {
        font-size: 20px;
        line-height: 36px;
        margin-bottom: 5px;
    }

    .mystery-box-module .mystery-star {
        margin-top: -92px;
        width: 258px;
        margin-left: -91px;
    }
}

@media (max-width: 850px) {
    .mystery-box-module .mystery-box-img .wine-count {
        margin-left: -31px;
        width: 75px;
        height: 75px;
    }

    .mystery-box-module .mystery-box-img .wine-count span {
        font-size: 47px;
    }
}

@media (max-width: 780px) {
    .mystery-box-module h3 {
        font-size: 19px;
    }
}

@media (max-width: 767px) {
    .mystery-box-module ul {
        margin-bottom: 25px;
    }
}

@media (max-width: 575px) {
    .mystery-box-module {
        padding: 45px 0 55px 0;
    }

    .mystery-box-module .mystery-box-img .box-img h3 {
        font-size: 30px;
        margin-left: -100px;
    }

    .mystery-box-module .mystery-box {
        width: 90px;
    }
}

@media (max-width: 425px) {

    .mystery-box-module .mystery-box-img .box-img img {
        width: 325px;
    }

    .mystery-box-module .mystery-box-img .bottle img {
        width: 140px;
    }

    .mystery-box-module .mystery-box-img .wine-count {
        width: 65px;
        height: 65px;
    }

    .mystery-box-module .mystery-box-img .wine-count span {
        font-size: 40px;
    }

    .mystery-box-module .mystery-star {
        display: none;
    }
}

@media (max-width: 370px) {

    .mystery-box-module .mystery-box-img .box-img h3 {
        font-size: 22px;
        margin-left: -75px;
        margin-top: -27px
    }

    .mystery-box-module .mystery-box-img .wine-count {
        margin-left: -22px;
        width: 57px;
        height: 57px;
    }

    .mystery-box-module .mystery-box-img .wine-count span {
        margin-top: -3px;
        font-size: 31px;
    }
}